import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { HttpRequest } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EMPTY, firstValueFrom, from as fromPromise, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthHttpService } from './auth-http.service';
import { Credentials } from '../interfaces/credentials';
import { GoogleSignInService } from './google-sign-in.service';
// import { HotjarService } from '../../../services/hotjar.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LoginDialogComponent } from '../components/login-dialog/login-dialog.component';
import { SessionInfo } from '../interfaces/session-info';
import { SignInResponse } from '../interfaces/sign-in-response';
import { TelemetryService } from '../../../services/telemetry.service';

import { environment } from 'environments/environment';

// AuthService handles internal session info and provides public methods to query the session info
@Injectable()
export class AuthService implements OnDestroy {

  private CLIENT_KEY = 'CLIENT';
  private COOKIE_CONSENT_KEY = 'COOKIE-CONSENTS';
  private PARTNER_HIT_KEY = 'PARTNER_HIT';
  private SESSION_KEY = 'SESSION_INFO';
  private TOKEN_KEY = 'TOKEN';

  // Model
  private modalPromise = null;
  private sessionInfo: SessionInfo = null;
  private socialSignInResponse: SignInResponse; // External auth provider sign-in info

  // Subjects for observables
  private authProviderActionSubject = new Subject<SignInResponse>(); // External auth provider action required - MERGE-ACCOUNTS or SIGN-UP

  // Subscriptions
  private authProviderActionSubscription: Subscription;
  private googleSignInSubscription: Subscription;

  constructor(
    private authHttpService: AuthHttpService,
    private googleSignInService: GoogleSignInService,
    // private hotjarService: HotjarService,
    private localStorageService: LocalStorageService,
    private location: Location,
    private modalService: NgbModal,
    private telemetryService: TelemetryService
  ) {

    // Read session info from storage
    const item = this.localStorageService.getItem(this.SESSION_KEY);

    // Parse session info (bug from previous implementation requires the double parsing)
    this.sessionInfo = item ? JSON.parse(JSON.parse(item)) : null;

    // TODO - AngularJS stores values as strings, remove once AngularJS is not used any more
    const clientId = this.localStorageService.getItem(this.CLIENT_KEY);
    if (clientId && clientId[0] === '"') {
      this.localStorageService.setItem(this.CLIENT_KEY, JSON.parse(clientId));
    } else if (!clientId || !clientId.length) {

      // Client ID doesn't exist yet, generate one
      this.generateClientId();
    }

    // Switch session id into token id and store the new session info in the local storage
    // TODO - TEMPORARY, remove later
    /*if (this.sessionInfo && !this.sessionInfo.TokenId && this.sessionInfo.SessionId) {
      this.sessionInfo.TokenId = this.sessionInfo.SessionId;

      // TODO - UNCOMMENT later, for now keep it to preserve compatibility with old versions
      // delete this.sessionInfo.SessionId;
      this.localStorageService.setItem(this.SESSION_KEY, JSON.stringify(JSON.stringify(this.sessionInfo)));
    }*/

    // Update telemetry
    this.updateTelemetry();

    // Subscribe the google sign-in service
    this.googleSignInSubscription = this.googleSignInService.getGoogleSignInObservable()
      .subscribe((signInResponse) => {
        this.socialSignInResponse = signInResponse;
        if (this.socialSignInResponse) {
          this.localStorageService.setItem(this.TOKEN_KEY, this.socialSignInResponse.Token);
          this.socialSignIn();
        } else {
          this.localStorageService.removeItem(this.TOKEN_KEY);
          this.socialSignOut();
        }
      })
  }

  ngOnDestroy() {

    // Unsubscribe
    if (this.googleSignInSubscription) {
      this.googleSignInSubscription.unsubscribe();
    }
  }

  createSession(sessionData) {
    this.sessionInfo = {
      TokenId: sessionData.TokenId,
      UserId: sessionData.UserId,
      OrganizationId: sessionData.OrganizationId || null,
      FirstName: sessionData.FirstName,
      LastName: sessionData.LastName,
      Email: sessionData.Email,
      IsContractor: sessionData.IsContractor || null,
      ConfirmationPages: sessionData.ConfirmationPages || null,
      Roles: sessionData.Roles,
      ScholarshipsAvailable: (sessionData.ScholarshipsAvailable || null),
      StaffInfo: sessionData.StaffInfo,
      License: (sessionData.License || null),
      LicensedFeatures: sessionData.LicensedFeatures || null,
      VisitorType: sessionData.VisitorType || 'lead'
    };
    this.localStorageService.setItem(this.SESSION_KEY, JSON.stringify(JSON.stringify(this.sessionInfo)));

    // Generate client id in the local storage for customers
    // Only customers get client ids, employees are ignored
    /*if (this.isAuthorized(['CUSTOMER'])) {
      this.generateClientId();
    }*/

    // Update telemetry
    this.updateTelemetry();
  };

  // Return external auth provider action observable
  getAuthProviderActionObservable() {
    return this.authProviderActionSubject.asObservable();
  }

  // Get requested confirmation pages
  getConfirmationPages() {
    return this.sessionInfo && this.sessionInfo.ConfirmationPages;
  }

  // Publish the token (originally session id)
  getSessionId() {
    return this.sessionInfo && this.sessionInfo.TokenId;
  }

  // Publish the session info
  getSessionInfo() {
    return this.sessionInfo;
  }

  // Publish the token
  getTokenId() {
    return this.sessionInfo && this.sessionInfo.TokenId;
  }

  // Publish the user id
  getUserId() {
    return this.sessionInfo && this.sessionInfo.UserId;
  }

  getVisitorType() {
    return (this.sessionInfo && this.sessionInfo.VisitorType) || 'anonymous';
  }

  // Check whether the user is authenticated
  isAuthenticated(): boolean {
    return !!this.sessionInfo;
  }

  // Check whether the signed-in user has one of the passed roles assigned
  isAuthorized(roles: string[]): boolean {

    // Check roles if user is authenticated
    if (this.sessionInfo && this.sessionInfo.Roles) {
      for (let i = 0; i < this.sessionInfo.Roles.length; i++) {
        if (roles.indexOf(this.sessionInfo.Roles[i]) !== -1) {
          return true;
        }
      }
    }

    // User is not authenticated or not authorized
    return false;
  }

  isConfirmationRequested(routingPath: string): boolean {

    const confirmationPages = this.getConfirmationPages();
    if (confirmationPages) {

      // Check if the current page is in the confirmation pages (or is the confirmation page itself)
      const pages = ',' + environment.urlConfirmationPath + ',' + confirmationPages + ',';
      const path = routingPath || this.location.path();
      if (!pages.includes(',' + path.substring(1).toLowerCase() + ',')) {
        window.location.href = '/' + environment.urlConfirmationPath + '?url=' + encodeURIComponent(window.location.href);
        return true;
      }
    }

    return false;
  }

  // Is this user a contractor?
  // TODO - Switch to this.sessionInfo.IsContractor
  isContractor(): boolean {
    return (this.sessionInfo && this.sessionInfo.StaffInfo) ? !!this.sessionInfo.StaffInfo.IsContractor : false;
  }

  // TODO - Switch to this.sessionInfo.IsContractor !== null
  isEmployee(): boolean {
    return this.sessionInfo && this.sessionInfo.StaffInfo !== null && typeof(this.sessionInfo.StaffInfo) !== 'undefined';
  }

  // TODO - Switch to this.sessionInfo.LicensedFeatures
  isLicensed(feature): boolean {
    return this.isAuthenticated() && this.sessionInfo.License &&
      this.sessionInfo.License.Features.split(',').includes(feature);
  }

  // Remove confirmation page when it was postponed
  removeConfirmationPage(page: string): void {

    if (!this.sessionInfo || !this.sessionInfo.ConfirmationPages) {
      return;
    }

    //let pages = this.sessionInfo.ConfirmationPages.split(',').filter((item) => item !== page);
    let pages = this.sessionInfo.ConfirmationPages.split(',');
    let first = pages.findIndex((item) => item === page);
    if (first > -1) {
      pages.splice(first, 1);
    }
    this.sessionInfo.ConfirmationPages = pages.join(',');
    this.localStorageService.setItem(this.SESSION_KEY, JSON.stringify(JSON.stringify(this.sessionInfo)));
  }

  // Open login dialog and return promise
  signIn(mode): Promise<any> {

    // For non-CwK projects, redirect to sign-in page
    if (environment.project !== 'cwk') {
      window.location.assign('/sign-in?m=' + mode);
      return Promise.resolve();
    }

    // If dialog is already opened, exit returning the promise
    if (this.modalPromise) {
      return this.modalPromise;
    }

    // If this is a refresh of an external token, refresh it using the social login service
    if (mode === 'refresh-session' && this.sessionInfo && this.sessionInfo.AuthProvider === 'GOOGLE') {
      const token = this.localStorageService.getItem(this.TOKEN_KEY);
      return this.googleSignInService.refreshToken(token)
        .catch((error) => firstValueFrom(this.getAuthProviderActionObservable()));
        /*.then((response) => {})
        .catch((error) => {
          console.log('GOOGLE ERROR: ', error);
          const result = firstValueFrom(this.getAuthProviderActionObservable())
            .then(value => {
              console.log('LAST VALUE FROM:', value);
              return value;
            })
            .catch(error => console.log('LAST VALUE ERROR: ', error));
          return result;
        });*/
    }

    // Open the login dialog
    const modalRef = this.modalService.open(LoginDialogComponent, { backdrop: 'static' });
    modalRef.componentInstance.mode = mode;

    // Store the promise
    this.modalPromise = modalRef.result;

    // Process backend response once the dialog is closed with success
    this.modalPromise
      .then((value: SessionInfo) => {

        // Process the sign-in response
        this.signInSuccess(value);

        // Clear the private modal promise
        this.modalPromise = null;
      })
      .catch(() => {

        // Sign out
        this.signOut()
          .subscribe(() => {});

        // Clear the promise
        this.modalPromise = null
      });

    // Return dialog's promise
    return this.modalPromise;
  }

  // Sign out user
  signOut(): Observable<any> {

    // Exit if not authenticated
    if (!this.sessionInfo) {
      return EMPTY;
    }

    // Call backend to sign out
    return (this.sessionInfo.Roles === 'STUDENT' ? this.authHttpService.signOutStudent() : this.authHttpService.signOut(this.sessionInfo.TokenId))
      .pipe(
        map(() => {

          // If the social user is signed in, sign them out
          if (this.socialSignInResponse) {
            this.googleSignInService.signOut();
            this.socialSignInResponse = null;
            this.localStorageService.removeItem(this.TOKEN_KEY);
          }

          // Remove session info
          this.sessionInfo = null;

          // Update telemetry
          this.updateTelemetry();

          // Get client id from local storage in order to preserve it
          const clientId = this.localStorageService.getItem(this.CLIENT_KEY);

          // Get cookie consents from local storage in order to preserve it
          const cookieConsent = this.localStorageService.getItem(this.COOKIE_CONSENT_KEY);

          // Get partner info from local storage in order to preserve it
          const partnerInfo = this.localStorageService.getItem(this.PARTNER_HIT_KEY);

          // Remove session info from storage
          this.localStorageService.clear();

          // Restore client id
          if (clientId) {
            this.localStorageService.setItem(this.CLIENT_KEY, clientId);
          }

          // Restore cookie consent
          if (cookieConsent) {
            this.localStorageService.setItem(this.COOKIE_CONSENT_KEY, cookieConsent);
          }

          // Restore partner info
          if (partnerInfo) {
            this.localStorageService.setItem(this.PARTNER_HIT_KEY, partnerInfo);
          }
        })
      );
  }

  // Sign in success
  signInSuccess(sessionInfo: SessionInfo) {

    // If different user is signed in, clean up
    if (this.sessionInfo && (sessionInfo.Email !== this.sessionInfo.Email)) {

      // Remove session info from storage
      this.localStorageService.removeItem(this.SESSION_KEY);
    }

    // Store the session info
    this.sessionInfo = sessionInfo;

    // Store the session info to storage (bug from previous implementation requires double serialization)
    this.localStorageService.setItem(this.SESSION_KEY, JSON.stringify(JSON.stringify(this.sessionInfo)));

    // Update telemetry
    this.updateTelemetry();

    // Redirect to confirmations if requested
    this.isConfirmationRequested(null);
  }

  // Generate client id to be stored with page hit and telemetry records
  private generateClientId() {

    const clientId = this.localStorageService.getItem('CLIENT');
    if (!clientId) {

      // Get UTC date in milliseconds
      const utc = new Date().getTime();

      // Get time elapsed since page visit start
      const delay = performance.now();

      // Random values
      const randomValue = new Uint32Array(1);
      crypto.getRandomValues(randomValue);

      this.localStorageService.setItem('CLIENT', utc.toString(36) + '-' + delay.toString(36) + '-' + randomValue[0].toString(36));
    }
  }

  private socialSignIn(): void {

    // Prepare credentials
    const credentials: Credentials = {
      AuthProvider: 'GOOGLE',
      Email: this.socialSignInResponse.Email,
      Password: null,
      Timezone: null,
      Token: this.socialSignInResponse.Token
    };

    // Send credentials to backend
    this.authHttpService.signIn(credentials)
      .subscribe(
        response => {
          this.socialSignInResponse.Status = 'OK';
          this.socialSignInResponse.SessionInfo = response;
          this.authProviderActionSubject.next(this.socialSignInResponse);
        },
        error => {
          this.socialSignInResponse.Status = error.error;
          this.authProviderActionSubject.next(this.socialSignInResponse);
        }
      );
  }

  private socialSignOut(): void {

    // Sign out
    this.signOut()
      .subscribe(() => {});

    // Indicate sign out to the refresh observable
    this.authProviderActionSubject.next(null);
  }

  // Update HotJar and CwK telemetry
  private updateTelemetry() {
    if (this.isEmployee()) {

      // Remove HotJar scripts & remove event handlers from telemetry service
      // this.hotjarService.remove();
      this.telemetryService.remove();
    } else {

      // Inject HotJar script
      // this.hotjarService.inject();
    }

    // Update Telemetry flags
    this.telemetryService.setIsEmployee(this.isEmployee());
  }
}
