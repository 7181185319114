import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionQueryParamsService {

  // Watched URL params to be stored in session storage
  // We watch URLPARAM discount only
  private queryParams = ['d'];

  // Session storage key prefix
  private storagePrefix = 'PARAM_';

  constructor(
    private activatedRoute: ActivatedRoute,
    private sessionStorageService: SessionStorageService
  ) {

    // Watch URL params
    this.activatedRoute.queryParamMap.subscribe(params => this.updateParams(params));
  }

  // Return a value of a single param
  getParam(key: string): string {
    return this.sessionStorageService.getItem(this.storagePrefix + key);
  }

  // Return query string with all set params
  getQueryString(): string {

    const queryStringParams = [];

    for (const key of this.queryParams) {

      // Read the value from the session storage
      const value = this.sessionStorageService.getItem(this.storagePrefix + key);

      if (value) {
        queryStringParams.push(key + '=' + value);
      }
    }

    return queryStringParams.join('&');
  }

  // Params have changed, store them in the session storage
  private updateParams(params: ParamMap): void {

    for (const key of this.queryParams) {

      // Get the params value
      const value = params.get(key);

      if (value) {
        this.sessionStorageService.setItem(this.storagePrefix + key, value);
      }
    }
  }
}
