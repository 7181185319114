import { AfterViewInit, Component, Inject, Input, LOCALE_ID, OnInit, SimpleChanges } from '@angular/core';
import { Router, ResolveEnd } from '@angular/router';

import { environment } from 'environments/environment';

import { AuthService } from '../../modules/auth/services/auth.service';
import { StaffDataService } from '../../services/staff-data.service';
import { TelemetryService } from '@shared/services/telemetry.service';

@Component({
  selector: 'cwk-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss', '../../shared/styles/header-menu.scss'],
  providers: [StaffDataService]
})
export class PageHeaderComponent implements OnInit {

  environmentName: string;
  errorReadingUpcomingLesson = '';
  homeUrl: string;
  isLocaleEn = true;
  isReadingUpcomingLesson = true;
  upcomingLesson: any = null;
  urlRoot = environment.urlRoot;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private authService: AuthService,
    private router: Router,
    private staffDataService: StaffDataService,
    private telemetryService: TelemetryService
  ) {

    // Set telemetry once the router has resolved the final route
    this.router.events.subscribe((routerData) => {
      if (routerData instanceof ResolveEnd) {
        this.setTelemetry(routerData.url);
      }
    });
  }

  ngOnInit() {

    // Set environment name if defined
    if (environment.name) {
      this.environmentName = environment.name.toUpperCase();
    }

    // Set home URL
    this.homeUrl = environment.urlRoot;

    // Check locale
    this.isLocaleEn = !this.locale || (this.locale.substring(0, 2) === 'en');
  }

  isInstructor(): boolean {
    return this.authService.isAuthorized(['TEACHER']);
  }

  isLicensed(feature: string) {
    return this.authService.isLicensed(feature);
  }

  isManager(): boolean {
    return this.authService.isAuthorized(['ADMIN', 'MANAGER', 'ORGADMIN', 'REGMANAGER']);
  }

  isTimeTracking(): boolean {
    return !this.authService.isAuthorized(['ADMIN', 'ORGADMIN', 'PAYROLL', 'TIMESHEET']);
  }

  private readUpcomingLesson(): void {

    if (!this.isInstructor()) {
      return;
    }

    this.errorReadingUpcomingLesson = '';
    this.isReadingUpcomingLesson = true;
    this.upcomingLesson = null;

    // Read upcoming lesson
    this.staffDataService.readUpcomingLesson(this.authService.getUserId())
      .subscribe(
        (value) => {
          this.upcomingLesson = value;
          this.isReadingUpcomingLesson = false;
        },
        (error) => {
          this.errorReadingUpcomingLesson = error;
          this.isReadingUpcomingLesson = false;
        }
      );
  }

  private setTelemetry(sourcePath: string) {
    this.telemetryService.inject('CLICK', 'announcement-bar-left-link', sourcePath, true);
    this.telemetryService.inject('CLICK', 'announcement-bar-right-link', sourcePath, true);
    this.telemetryService.inject('CLICK', 'cwk-logo', sourcePath, true);
  }
}

