import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { throwError as observableThrowError, Observable, from as fromPromise } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '../modules/auth/services/auth.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // If request results in unauthorized error, ask for login
    return next.handle(this.applyToken(request))
      .pipe(
        catchError((error: any) => {
            if ((error instanceof HttpErrorResponse) && (error.status === 401)) {

              // Ask for credentials
              return fromPromise(this.authService.signIn('refresh-session'))
                .pipe(switchMap(value => next.handle(this.applyToken(request))));
            } else {
              return observableThrowError(error);
            }
          }
        )
      );
  }

  // If authenticated, append token to the passed request and return modified request
  private applyToken(request: HttpRequest<any>): HttpRequest<any> {

    // Get session info
    const sessionInfo = this.authService.getSessionInfo();

    // If authenticated, add token to request params
    if (sessionInfo) {
      const params = request.params.set('uuid', sessionInfo.TokenId);
      request = request.clone({ params: params });
    }

    return request;
  }
}
