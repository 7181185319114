import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable()
export class LocationsMenuDataService {

  private urlRegions = environment.urlWebServices + '/api/regions';

  constructor(private http: HttpClient) { }

  read(): Observable<any> {

    // Call web service
    const url = `${this.urlRegions}/locationsMenu`;
    return this.http.get(url)
      .pipe(
        tap(this.fixFromMySQL),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  private fixFromMySQL(countries) {

    countries.forEach(country => {

      // Parse JSON objects
      country.Regions = country.Regions ? JSON.parse(country.Regions) : [];
    });

    return countries;
  }
}
