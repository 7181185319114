import { Component, Input, OnInit } from '@angular/core';

// Icons
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'cwk-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss']
})
export class ErrorAlertComponent implements OnInit {

  @Input() error: any;
  @Input() isHomeApp = true;

  // Icons
  faExclamationTriangle = faExclamationTriangle;

  // Model
  isHtml = false;

  constructor() { }

  ngOnInit() {

    // Check whether the error message is HTML formatted
    this.isHtml = this.error.startsWith('<html>');
  }

  refresh() {
    window.location.reload();
  }
}
