import { Component } from '@angular/core';

import { CookieConsentManagerService } from '@shared/components/cookie-consent-manager/services/cookie-consent-manager.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'cwk-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})

export class PageFooterComponent {

  // Model
  now: Date = new Date();
  urlRoot = environment.urlRoot;

  constructor(
    private cookieConsentManagerService: CookieConsentManagerService
  ) { }

  goUrl(url: string) {
    window.location.href = environment.urlRoot + url/* + window.location.search*/;
  }

  onClickManageCookies() {
    this.cookieConsentManagerService.manageCookies();
  }
}
