import { AfterViewInit, Component, OnInit } from '@angular/core';

import { ConfigurationService } from '@shared/services/configuration.service';
import { TelemetryService } from '@shared/services/telemetry.service';

// Icons
import { faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-info-graphics',
  templateUrl: './info-graphics.component.html',
  styleUrls: ['./info-graphics.component.scss']
})
export class InfoGraphicsComponent implements AfterViewInit, OnInit {

  infoGraphics = [
    /*{
      alt: 'Number of years Coding with kids has been teaching',
      configKey: 'INFO-GRAPHIC-YEARS',
      display: 'years of<br/>Coding with Kids',
      error: null,
      id: 'infographic-1',
      imageURL: 'https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/info-graphics/no-labels/1.png',
      isReading: false,
      value: null
    },*/
    {
      alt: 'Number of students taught',
      configKey: 'INFO-GRAPHIC-STUDENTS',
      display: 'students taught',
      error: null,
      id: 'infographic-2',
      imageURL: 'https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/info-graphics/no-labels/2.png',
      isReading: true,
      value: null
    },
    {
      alt: 'Number of virtual students taught',
      configKey: 'INFO-GRAPHIC-VIRTUAL-STUDENTS',
      display: 'online students',
      error: null,
      id: 'infographic-3',
      imageURL: 'https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/info-graphics/no-labels/3.png',
      isReading: true,
      value: null
    },
    {
      alt: 'Number of locations',
      configKey: 'INFO-GRAPHIC-LOCATIONS',
      display: 'school partners',
      error: null,
      id: 'infographic-4',
      imageURL: 'https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/info-graphics/no-labels/4.png',
      isReading: true,
      value: null
    }
  ];

  // Icons
  faExclamationTriangle = faExclamationTriangle;
  faSpinner = faSpinner;

  constructor(
    private configurationService: ConfigurationService,
    private telemetryService: TelemetryService
  ) { }

  ngAfterViewInit() {

    // Set telemetry after the view has initialized
    this.infoGraphics.forEach(infoGraphic => {
      this.telemetryService.inject('CLICK', infoGraphic.id, '/', true);
    });
  }

  ngOnInit() {

    // For each info graphic get its value from the configuration table and set telemetry
    this.infoGraphics.forEach(infoGraphic => {

      switch (infoGraphic.configKey) {
        case 'INFO-GRAPHIC-YEARS':

          // CwK started on 12/1/2013
          const start = new Date('2013-12-01T00:00:00');
          const now = new Date();

          // Calculate the year difference (approximation, we assume 365.25 days per year to accomodate leap years)
          const diff = Math.round(10 * (now.getTime() - start.getTime()) / (1000 * 60 * 60 * 24 * 365.25)) / 10;
          const parts = diff.toString().split('.');
          infoGraphic.value = parts[0] + (parts[1] ? '<small><small>.' + parts[1] + '</small></small>' : '');
          break;

        default:

          this.configurationService.getItem(infoGraphic.configKey)
            .subscribe(
              (value) => infoGraphic.value = value.Value,
              (error) => infoGraphic.error = error
            )
            .add(() => infoGraphic.isReading = false);
      }

      this.telemetryService.inject('CLICK', infoGraphic.id, '/', true);
    });
  }
}
