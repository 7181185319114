import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';

import { environment } from 'environments/environment';

@Injectable()
export class AuthGuard  {

  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const roles = route.data['roles'];
    const licenseFeature = route.data['licenseFeature'];
    const path = state.url;

    // Check whether the user is authenticated and authorized to access the route
    if (!this.authService.isAuthenticated()) {
      return this.authService.signIn('routing')
        .then(
          () => this.hasValidPermissions(roles, licenseFeature, path),
          () => false
        );
    } else if (!this.hasValidPermissions(roles, licenseFeature, path)) {

      // TODO - Navigate to page that shows info about access restriction
      return false;
    } else {
      return true;
    }
  }

  private hasValidPermissions(roles: string[], licenseCode: string, routingPath: string) {
    return !this.authService.isConfirmationRequested(routingPath) && this.authService.isAuthorized(roles) && (!licenseCode || this.authService.isLicensed(licenseCode));
  }
}
