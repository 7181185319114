import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';

import { AwardsAndRecognitionComponent } from './components/awards-and-recognition/awards-and-recognition.component';
import { BlogPostsCarouselComponent } from './components/blog-posts-carousel/blog-posts-carousel.component';
import { CodersLadderPathwaysComponent } from './components/coders-ladder-pathways/coders-ladder-pathways.component';
import { CustomerTestimonialsComponent } from './components/customer-testimonials/customer-testimonials.component';
import { HomepageRoutingModule } from './homepage-routing.module';
import { HomepageComponent } from './homepage.component';
import { ProgramTilesComponent } from './components/program-tiles/program-tiles.component';
import { QualityCommitmentComponent } from './components/quality-commitment/quality-commitment.component';
import { StudentSpotlightComponent } from './components/student-spotlight/student-spotlight.component';
import { TeachingPhilosophyComponent } from './components/teaching-philosophy/teaching-philosophy.component';

@NgModule({
    declarations: [
        AwardsAndRecognitionComponent,
        BlogPostsCarouselComponent,
        CodersLadderPathwaysComponent,
        CustomerTestimonialsComponent,
        HomepageComponent,
        ProgramTilesComponent,
        QualityCommitmentComponent,
        StudentSpotlightComponent,
        TeachingPhilosophyComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        HomepageRoutingModule,
        NgbModule,
        SharedModule
    ]
})
export class HomepageModule { }
