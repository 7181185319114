import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Countries } from '../../classes/countries';
import { LocalStorageService } from '../../services/local-storage.service';
import { DialogJoinMailingListDataService } from './dialog-join-mailing-list-data.service';
import { TrackingPixelService } from '../../services/tracking-pixel.service';

@Component({
  selector: 'cwk-dialog-join-mailing-list',
  templateUrl: './dialog-join-mailing-list.component.html',
  styleUrls: ['./dialog-join-mailing-list.component.scss'],
  providers: [LocalStorageService, DialogJoinMailingListDataService]
})
export class DialogJoinMailingListComponent implements OnInit {

  @Input() countryCode: string;
  @Input() source: string;
  @ViewChild('subscribeForm') public subscribeForm: NgForm;

  private storageKeyClient = 'CLIENT';

  // Icons
  faSpinner = faSpinner;
  faTimes = faTimes;

  // Convert associated array of countries to array for easier processing in typeahead
  countries = Object.keys(Countries.countries).map(k => {
    return {
      Code: k,
      Name: Countries.countries[k].Name
    }
  });
  country: any = null;
  errorSaving: string = null;
  isSaved = false;
  isSaving = false;
  mailingListInfo = {
    Email: '',
    FirstName: '',
    LastName: '',
    ZIP: ''
  }

  constructor(
    private activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute,
    private dialogJoinMailingListDataService: DialogJoinMailingListDataService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private trackingPixelService: TrackingPixelService
  ) { }

  ngOnInit() {

    // Set the country object
    this.country = this.countries.find(c => c.Code === this.countryCode);
  }

  cancel() {
    this.activeModal.dismiss();
  }

  close() {
    this.activeModal.close();
  }

  countryExists() {
    return typeof this.country === 'object' ? true : !!this.countries.find(c => c.Name === this.country);
  }

  formatCountry = (result: any) => result.Name;

  isFormValid() {
    return this.subscribeForm.valid && this.countryExists();
  }

  // Search country
  searchCountry = (text: Observable<string>) =>
    text
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(term => this.countries.filter(v => v.Name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )

  submit() {

    // Clear error
    this.errorSaving = '';

    // Show spinner
    this.isSaving = true;

    // Prepare to payload
    const payload = {
      Email: this.mailingListInfo.Email,
      FirstName: this.mailingListInfo.FirstName,
      LastName: this.mailingListInfo.LastName,
      Country: this.country.Code,
      Source: this.source,
      ZIP: this.mailingListInfo.ZIP,
      ClientId: this.localStorageService.getItem(this.storageKeyClient),
      PartnerUrlId: this.activatedRoute.snapshot.queryParamMap.get('p'),
      UTMCampaign: this.activatedRoute.snapshot.queryParamMap.get('utm_campaign'),
      UTMContent: this.activatedRoute.snapshot.queryParamMap.get('utm_content'),
      UTMMedium: this.activatedRoute.snapshot.queryParamMap.get('utm_medium'),
      UTMSource: this.activatedRoute.snapshot.queryParamMap.get('utm_source'),
      UTMTerm: this.activatedRoute.snapshot.queryParamMap.get('utm_term'),
      URL: this.router.url
    }

    // Execute the handler
    this.dialogJoinMailingListDataService.subscribe(payload)
      .subscribe(
        () => {
          this.isSaved = true;
          this.isSaving = false;

          // Notify tracking pixels
          this.trackingPixelService.subscriber(payload)
           /*.subscribe()*/;
        },
        error => {
          this.errorSaving = ((error.text && error.text()) || error);
          this.isSaving = false;
        }
      );
  }
}
