<!-- Online -->
<a class="dropdown-item" href="{{ urlRoot + '/region/online-programs' }}" id="locations-Online-Programs" tm-destination="/region/online-programs" class="dropdown-item">Online Programs</a>
<div class="dropdown-divider"></div>

<!-- Loading -->
<div *ngIf="!countries.length" class="item">
  <fa-icon class="me-2" [icon]="faSpinner" [spin]="true"></fa-icon>Loading...
</div>

<div *ngIf="countries.length" class="nested-drop">

  <!-- DESKTOP: Cascading USA right -->
  <div class="dropdown-item my-2">
    <div class="d-none d-md-block item dropdown dropstart">
      <div class="dropdown-toggle" data-toggle="dropdown">USA</div>
      <ng-container *ngTemplateOutlet="usaRegionsTemplate"></ng-container>
    </div>

    <!-- MOBILE: Cascading USA down -->
    <div ngbDropdown class="d-md-none dropdown-item d-inline-block w-100" (click)="showMobileUSA = !showMobileUSA">
      <div class="item" ngbDropdownToggle>USA</div>
      <div ngbDropdownMenu class="w-100">
        <ng-container *ngTemplateOutlet="usaRegionsTemplate"></ng-container>
      </div>
    </div>
  </div>

  <!-- Countries -->
  <div *ngFor="let country of countries; last as isLast">
    <a id="{{ 'locations-' + country.Country }}" [attr.tm-destination]="'/region/' + country.URLIdentifier" class="dropdown-item" href="{{ urlRoot + '/region/' + country.URLIdentifier }}" [ngClass]="{'mb-2' : !isLast}">{{ country.Country }}</a>
  </div>
</div>

<ng-template #usaRegionsTemplate>
  <div class="dropdown-menu shadow-lg" [ngClass]="{'show' : showMobileUSA}">
    <div *ngFor="let region of usaRegions; last as isLast">
      <a id="{{ 'locations-' + region.State + '-' + region.City }}" [attr.tm-destination]="'/region/' + region.URLIdentifier" class="dropdown-item" href="{{ urlRoot + '/region/' + region.URLIdentifier }}" [ngClass]="{'mb-2' : !isLast}">{{ region.State + ' - ' + region.City }}</a>
    </div>
  </div>
</ng-template>
