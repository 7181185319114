import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AuthGuard } from './classes/auth-guard';
import { AuthHttpService } from './services/auth-http.service';
import { AuthService } from './services/auth.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { SignInCustomerFormComponent } from './components/sign-in-customer-form/sign-in-customer-form.component';
import { SignInOutComponent } from './components/sign-in-out/sign-in-out.component';
import { SignInStudentFormComponent } from './components/sign-in-student-form/sign-in-student-form.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule
  ],
  declarations: [
    LoginDialogComponent,
    SignInCustomerFormComponent,
    SignInOutComponent,
    SignInStudentFormComponent
  ],
  exports: [
    SignInCustomerFormComponent,
    SignInOutComponent,
    SignInStudentFormComponent
  ],
  providers: [
    AuthGuard,
    AuthService,
    AuthHttpService,
    LocalStorageService
  ]
})
export class AuthModule { }
