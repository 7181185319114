<div class="row">
  <div class="col-sm-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
    <div class="tile-container">
      <img alt="Progressive curriculum" class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/teaching-philosophy/continuous-curriculum.png" loading="lazy"/>
      <div class="title">PROGRESSIVE CURRICULUM</div>
      <div class="mx-auto my-2 mini-orange-bar"></div>
      <div>All of Coding with Kids curriculum is designed to grow with our students. We have two main tracks: Our flagship classes climb up the <b>Coder's ladder<span class="cwk-registration-icon">&reg;</span></b>. Enrichment classes and camps follow <b>Coder's Pathways<span class="cwk-registration-icon">&reg;</span>.</b></div>
    </div>
  </div>
  <div class="col-sm-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
    <div class="tile-container">
      <img alt="Skills first, tools second" class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/teaching-philosophy/skills-first.png" loading="lazy"/>
      <div class="title">SKILLS FIRST, TOOLS SECOND</div>
      <div class="mx-auto my-2 mini-orange-bar"></div>
      <div><b>Great programmers know how to write great code.</b></div>
      <div>We focus on teaching coding skills and introduce students to different programming languages appropriate for their age and experience level.</div>
    </div>
  </div>
  <div class="col-sm-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
    <div class="tile-container">
      <img alt="Project focused" class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/teaching-philosophy/project-focused.png" loading="lazy"/>
      <div class="title">PROJECT<br class="d-none d-xl-block"/>&#32;FOCUSED&#32;</div>
      <div class="mx-auto my-2 mini-orange-bar"></div>
      <div><b>No boring theory lectures!</b></div>
      <div>Kids and teens are introduced to coding concepts as they build projects, from Scratch games to web applications.</div>
    </div>
  </div>
  <div class="col-sm-12 col-lg-6 col-xl-3 mb-0">
    <div class="tile-container">
      <img alt="Individualized approach" class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/teaching-philosophy/individualized-approach.png" loading="lazy"/>
      <div class="title">INDIVIDUALIZED APPROACH</div>
      <div class="mx-auto my-2 mini-orange-bar"></div>
      <div>We recognize that each child learns differently. Our curriculum allows students to <b>advance at their own pace</b>. Our goal is that no one is left in the dust and no one is bored by slow progress.</div>
    </div>
  </div>
</div>

