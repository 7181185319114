import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'environments/environment';

export interface SearchedStaff {
  Email: string;
  FirstName: string;
  LastName: string;
  UserId: number;
}

@Injectable()
export class StaffDataService {

  private urlStaff = environment.urlWebServices + '/api/staff';

  constructor(private http: HttpClient) { }

  readUpcomingLesson(staffId: number): Observable<any> {

    // Call web service
    const url = `${this.urlStaff}/upcomingLesson/${staffId}`;
    return this.http.get(url)
      .pipe(catchError(error => observableThrowError(error.message || error)));
  }

  search(roles: string, term: string, onlyActive: boolean): Observable<SearchedStaff> {

    // Pass data in params
    const params = new HttpParams()
      .set('onlyActive', +onlyActive)
      .set('roles', roles)
      .set('searchString', term);

    // Call web service
    return this.http.get<SearchedStaff>(`${this.urlStaff}/search`, { params: params })
      .pipe(catchError(error => observableThrowError(error.message || error)));
  }
}
