import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadScriptService {

  // From https://github.com/abacritt/angularx-social-login/blob/master/projects/lib/src/entities/base-login-provider.ts
  loadScript(
    id: string,
    src: string,
    onload: any,
    parentElement = null
  ): void {

    // Get document if platform is only browser
    if (typeof document !== 'undefined' && !document.getElementById(id)) {
      let newScript = document.createElement('script');

      newScript.async = true;
      newScript.src = src;
      newScript.onload = onload;

      if (!parentElement) {
        parentElement = document.head;
      }

      parentElement.appendChild(newScript);
    }
  }
}
