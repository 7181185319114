<div class="row mb-5">
  <div class="col-md-12 col-lg-6 mb-5 mb-lg-0">
    <div class="d-flex flex-column h-100 justify-content-between">
      <div>
        <div class="text-center">
          <a tm-destination="/coders-ladder-group-classes" class="cwk-link" [routerLink]="['/coders-ladder-group-classes']" queryParamsHandling="preserve">
            <div class="cwk-grey top-title">Coder's Ladder<span class="cwk-registration-icon">&reg;</span></div>
          </a>
          <div>On-going core programming academy</div>
          <!--div class="yellow-title">CODER'S LADDER CLASSES</div-->
          <img class="img-fluid mt-4" src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-ladder/coders-ladder.png" alt="Coder's ladder chart" width="482" height="447" loading="lazy">
        </div>
      </div>
      <div>
        <!--div class="mt-5 text-justify">
          Coding with Kids curriculum is designed to grow with our students. The Coder's Ladder<span class="cwk-registration-icon">&reg;</span>, similar to martial arts belt system, defines computer programming skills from beginner to advanced levels. With each step up the ladder, students gain proficiency in specific programming skills and add new, more complex projects to their portfolio. Levels are taught in age and experience appropriate development environments, from Scratch to professional programming languages, such as Python or Java. The Coder’s Ladder<span class="cwk-registration-icon">&reg;</span> Classes are the flagship program of Coding with Kids’ academy. Benefits include small group sizes (max. 6, guaranteed), individualized pace, progress tracking, and level graduations based on final project-based exams.
        </div-->
        <!--div class="d-flex justify-content-center mt-5">
          <a id="coders-ladder-learn-more-button" tm-destination="/coders-ladder" class="link-btn cwk-link" [routerLink]="['/coders-ladder-group-classes']" queryParamsHandling="preserve">
            <b>CODER'S LADDER<span class="cwk-registration-icon">&reg;</span></b><br/>LEARN MORE
          </a>
        </div-->
      </div>
    </div>
  </div>
  <div class="col-md-12 col-lg-6">
    <div class="d-flex flex-column h-100 justify-content-between">
      <div>
        <div class="text-center">
          <a tm-destination="/coders-pathways" class="cwk-link" [routerLink]="['/coders-pathways']" queryParamsHandling="preserve">
            <div class="cwk-grey top-title">Coder's Pathways<span class="cwk-registration-icon">&reg;</span></div>
          </a>
          <div>Short-term enrichment courses or camps, also option for private classes</div>
          <!--div class="yellow-title">ENRICHMENT CLASSES | CAMPS</div-->
          <img class="img-fluid mt-4" src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/coders-pathways-2024.png" alt="Coder's pathways chart" width="360" height="482" loading="lazy" />
        </div>
      </div>
      <div>
        <!--div class="mt-5 text-justify">
          The Coder’s Pathways<span class="cwk-registration-icon">&reg;</span> have been developed as an alternative to our Coder’s Ladder<span class="cwk-registration-icon">&reg;</span> curriculum for shorter programs, such as afterschool enrichment classes or camps. Each pathway is sectioned into multiple short-term courses and offers a clear progression of learning material, from Beginner to Advanced levels. While the Coder’s Ladder<span class="cwk-registration-icon">&reg;</span> curriculum focuses on teaching core coding skills and languages, the Coder’s Pathways<span class="cwk-registration-icon">&reg;</span> offer a wider range of specialties (think Minecraft Modding, 3D Game Design in Unity or Robotics). We encourage our students to follow any (or all!) of our Coder’s Pathways<span class="cwk-registration-icon">&reg;</span> to add versatility to their coding skills, even if they are attending the Coder’s Ladder<span class="cwk-registration-icon">&reg;</span> academy. There are many different paths to becoming a top coder!
        </div-->
        <!--div class="d-flex justify-content-center mt-5">
          <a id="coders-pathways-learn-more-button" tm-destination="/coders-pathways" class="link-btn cwk-link" [routerLink]="['/coders-pathways']" queryParamsHandling="preserve">
            <b>CODER'S PATHWAYS<span class="cwk-registration-icon">&reg;</span></b><br/>LEARN MORE
          </a>
        </div-->
      </div>
    </div>
  </div>
</div>

<!-- CL & CP VIDEO -->
<div class="row mb-5">
  <div class="col-3"></div>
  <div class="col-lg-6">
    <div class="text-center mb-3">Learn about the Coder’s Ladder<span class="cwk-registration-icon">&reg;</span> and Coder’s Pathways<span class="cwk-registration-icon">&reg;</span> in this video.</div>
    <home-embed-video videoSrc="https://player.vimeo.com/video/449906308?portrait=0&portrait=0&byline=0&title=0" videoTitle="Coding with Kids Coder's Ladder and Coder's Pathways"></home-embed-video>
  </div>
</div>
