import { Component, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { ResizeService } from '../../services/resize.service';

@Component({
  selector: 'cwk-size-detector',
  templateUrl: './size-detector.component.html'
})
export class SizeDetectorComponent implements AfterViewInit {

  prefix = 'cwk-';
  sizes = [
    {
      bootstrapDisplayClass: 'd-block d-sm-none',
      name: 'xs'
    },
    {
      bootstrapDisplayClass: 'd-none d-sm-block d-md-none',
      name: 'sm'
    },
    {
      bootstrapDisplayClass: 'd-none d-md-block d-lg-none',
      name: 'md'
    },
    {
      bootstrapDisplayClass: 'd-none d-lg-block d-xl-none',
      name: 'lg'
    },
    {
      bootstrapDisplayClass: 'd-none d-xl-block d-xxl-none',
      name: 'xl'
    },
    {
      bootstrapDisplayClass: 'd-none d-xxl-block',
      name: 'xxl'
    }
  ];

  constructor(
     private elementRef: ElementRef,
     private resizeService: ResizeService
  ) { }

  @HostListener('window:resize', ['$event']) onResize($event) {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    const currentSize = this.sizes.find(size => {

      // Get the element with this size set
      const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${size.name}`);

      // Is this element visible?
      return window.getComputedStyle(el).display !== 'none';
    });

    // Update the subject in the service
    this.resizeService.setResizeSubject(currentSize.name);
  }

}
