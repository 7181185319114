import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UniqueIdService {

  constructor() { }

  get() {

    // Get UTC date in milliseconds
    const utc = new Date().getTime();

    // Get time elapsed since page visit start
    const delay = performance.now();

    // Random values
    const randomValue = new Uint32Array(1);
    crypto.getRandomValues(randomValue);

    return utc.toString(36) + '-' + delay.toString(36) + '-' + randomValue[0].toString(36);
  }
}
