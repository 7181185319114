  <div class="row">

    <!-- TEMPORARILY SHOW 10TH ANNIVERSARY SHIELD -->
    <div class="col-6 col-md-3 mb-3 mb-md-0 d-flex flex-column align-items-center pt-2 pt-md-4">
      <picture>
        <source srcset="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/shield-10th-anniversary.webp" type="image/webp" />
        <img alt="10 years of Coding with Kids Academy shield" class="img-fluid" loading="lazy" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/shield-10th-anniversary.png" width="174" height="203" />
      </picture>
    </div>

    <div *ngFor="let infoGraphic of infoGraphics" class="col-6 col-md-3 mb-3 mb-md-0 d-flex flex-column align-items-center">

      <!-- Loading value -->
      <div *ngIf="infoGraphic.isReading" class="cwk-loading cwk-orange">
        <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
      </div>

      <!-- Error loading value -->
      <div *ngIf="infoGraphic.error" class="cwk-loading cwk-red">
        <fa-icon [icon]="faExclamationTriangle" ngbPopover="{{ infoGraphic.error }}" triggers="mouseenter:mouseleave"></fa-icon>
      </div>

      <!-- Value -->
      <div *ngIf="infoGraphic.value" class="value" [innerHTML]="infoGraphic.value"></div>

      <!-- Display (e.g. "Students Taught") -->
      <div class="display" [innerHTML]="infoGraphic.display"></div>

      <!-- Info graphic image -->
      <img id="{{ infoGraphic.id }}" class="img-fluid icon" src="{{ infoGraphic.imageURL }}" alt="{{ infoGraphic.alt }}" loading="lazy"/>
    </div>
  </div>
