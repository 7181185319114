<div class="alert alert-danger fs-1 m-5 p-5 text-center" *ngIf="!isCwKWeb">
  Attention: this page illegally copies parts or all of the Coding&nbsp;with&nbsp;Kids&reg; website! Please copy<br/>
  <br/>
  <b>www.codingwithkids.com</b><br/>
  <br/>
  to your browser's address bar to continue to the official Coding&nbsp;with&nbsp;Kids&reg; website.<br/>
</div>
<div class="container" *ngIf="isCwKWeb">

  <!-- Size detector -->
  <cwk-size-detector></cwk-size-detector>

  <!-- Header of the page -->
  <cwk-page-header>
    <ng-container>
      <cwk-header-menu [activeTab]="activeTab" ownTabs="" *ngIf="isLocaleEn"></cwk-header-menu>
      <div class="region-switch d-none d-md-flex justify-content-between">
        <div class="d-flex">
          <cwk-region-switch *ngIf="hasRole(['ADMIN', 'MANAGER', 'REGMANAGER', 'TEACHER'])"></cwk-region-switch>
          <cwk-freshchat-switch *ngIf="hasRole(['ADMIN', 'FRESHCHAT'])" class="ms-5"></cwk-freshchat-switch>
        </div>
        <cwk-sign-in-out *ngIf="isLocaleEn"></cwk-sign-in-out>
      </div>
    </ng-container>
  </cwk-page-header>

  <!-- Content of the view -->
  <div class="router-outlet">
    <router-outlet></router-outlet>
  </div>

  <!-- Footer of the page -->
  <cwk-page-footer></cwk-page-footer>

  <!-- Fresh Chat icon -->
  <cwk-freshchat></cwk-freshchat>

  <!-- Cookie consent manager -->
  <shared-cookie-consent-manager></shared-cookie-consent-manager>
</div>
