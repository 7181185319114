import { Component, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import { CustomerTestimonialsDataService } from './services/customer-testimonials-data.service';
import { TelemetryService } from '@shared/services/telemetry.service';

// Icons
import { faChevronLeft, faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-customer-testimonials',
  templateUrl: './customer-testimonials.component.html',
  styleUrls: ['./customer-testimonials.component.scss']
})
export class CustomerTestimonialsComponent implements AfterViewInit {

  @ViewChild('carousel', {static: false}) carouselElement: ElementRef;

  errorReading = '';
  isMouseDown = false;
  isReading = true;
  scrollLeft: any;
  showLeftArrow = false;
  showRightArrow = false;
  startX = 0;
  testimonials: any[] = [];

  readonly CAROUSEL_SCROLL_WIDTH = 282; // The exact width of 1 tile plus any margins

  // Icons
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faSpinner = faSpinner;

  constructor(
    private customerTestimonialsDataService: CustomerTestimonialsDataService,
    private telemetryService: TelemetryService
  ) { }

  ngAfterViewInit() {
    this.readPublic();
  }

  mouseDown(event: MouseEvent, carousel: HTMLElement) {
    this.isMouseDown = true;
    this.startX = event.pageX - carousel.offsetLeft;
    this.scrollLeft = carousel.scrollLeft;
  }

  mouseMoved(event: MouseEvent, carousel: HTMLElement) {
    event.preventDefault();
    if (!this.isMouseDown) {
      return;
    }

    const x = event.pageX - carousel.offsetLeft;
    const scroll = x - this.startX;
    carousel.scrollLeft = this.scrollLeft - scroll;
  }

  mouseOff() {
    this.isMouseDown = false;
  }

  scrollCarousel(direction: string, carousel: HTMLElement) {
    carousel.scrollTo({ left: Math.min(Math.max(0, (carousel.scrollLeft + (direction === 'RIGHT' ? this.CAROUSEL_SCROLL_WIDTH :
        -this.CAROUSEL_SCROLL_WIDTH))), carousel.scrollWidth - carousel.clientWidth)});
  }

  updateArrows(carousel: HTMLElement) {
    this.showLeftArrow = carousel.scrollLeft > 0;
    this.showRightArrow = (carousel.scrollLeft + carousel.clientWidth) < carousel.scrollWidth;
  }

  private readPublic() {

    // Show spinner
    this.isReading = true;

    // Reset error
    this.errorReading = '';

    // Read
    this.customerTestimonialsDataService.readPublic()
      .subscribe(
        (value) => {

          // Set the data
          this.testimonials = value;

          // Shuffle
          // this.shuffleFeedback();

          // Set telemetry
          setTimeout(_ => {
            this.setTelemetry();
            if (this.carouselElement) {
              this.updateArrows(this.carouselElement.nativeElement);
            }
          });

          this.isReading = false;
        },
        (error) => {
          this.errorReading = error;
          this.isReading = false;
        }
      );
  }

  private shuffleFeedback() {
    for (let i = this.testimonials.length - 1; i > 0; i--) {
      const rand = Math.floor(Math.random() * (i + 1));
      [this.testimonials[i], this.testimonials[rand]] = [this.testimonials[rand], this.testimonials[i]];
    }
  }

  private setTelemetry() {
    this.telemetryService.inject('CLICK', 'testimonials-scroll-left', '/', true);
    this.telemetryService.inject('CLICK', 'testimonials-scroll-right', '/', true);
  }
}
