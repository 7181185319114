import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Subscription } from 'rxjs';

import { slideInOut } from '@shared/animations/slide-in-out';

import { environment } from 'environments/environment';
import { AuthHttpService } from '../../services/auth-http.service';
import { AuthService } from '../../services/auth.service';
import { Credentials } from '../../interfaces/credentials';
import { GeolocationService } from '../../../../services/geolocation.service';
import { GoogleSignInService } from '../../services/google-sign-in.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { SignInResponse } from '../../interfaces/sign-in-response';

@Component({
  animations: [slideInOut],
  selector: 'cwk-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnDestroy, AfterViewInit {

  @Input() mode: string;
  @ViewChild('googleSignInButton') private googleSignInButton!: ElementRef;

  // Icons
  faSpinner = faSpinner;

  credentials: Credentials = {
    AccountName: null,
    AuthProvider: 'CWK',
    Country: null,
    Email: '',
    FirstName: null,
    IsActive: false,
    LastName: null,
    Password: '',
    Timezone: null,
    Token: null
  };
  domainName = environment.domainName;
  isAnimationPaused = true; // Initially, don't run slideInOut animations
  isCwK = environment.project === 'cwk';
  loginMode = 'user'; // Login dialog mode, one of user / student
  providerSignInResponse: SignInResponse = null;
  rememberEmail: boolean;
  stage = 'LOGIN'; // One of LOGIN, MERGE-ACCOUNTS or SIGN-UP
  status: string;

  // Progress flags
  errorResendingVerification = '';
  isResendingVerification = false;
  isVerificationResent = false;

  private emailKey = 'LOGIN_EMAIL';

  // Subscriptions
  private authProviderActionSubscription: Subscription; // External auth provider actions - MERGE-ACCOUNTS or SIGN-UP
  private geolocationSubscription: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private authHttpService: AuthHttpService,
    private authService: AuthService,
    private geolocationService: GeolocationService,
    private googleSignInService: GoogleSignInService,
    private localStorageService: LocalStorageService,
    private ngZone: NgZone
  ) {

    // If email is stored, set it as default
    const storedEmail = this.localStorageService.getItem(this.emailKey);

    // TODO - AngularJS stores values as strings, remove once AngularJS is not used
    if (storedEmail && storedEmail[0] === '"') {
      this.localStorageService.setItem(this.emailKey, JSON.parse(storedEmail));
    }

    // Prepopulate credentials if stored
    if (storedEmail) {
      this.credentials.Email = storedEmail;
    }

    // Set members
    this.rememberEmail = !!storedEmail;
    this.status = '';

    // Observe any auth provider actions
    this.authProviderActionSubscription = this.authService.getAuthProviderActionObservable()
      .subscribe(
        (value) => this.onAuthProviderActionChange(value)
      );

    // Get current geolocation info
    this.geolocationSubscription = this.geolocationService.getObservable()
      .subscribe((value) => {
        this.credentials.Country = value.country_code;
        if (this.credentials.Country === 'US') {
          this.credentials.State = value.region_code;
        }
      });
  }

  ngAfterViewInit() {

    // Render the Google Sign-in button
    this.renderGoogleSignInButton();
  }

  ngOnDestroy() {

    // Unsubscribe observables
    if (this.authProviderActionSubscription) {
      this.authProviderActionSubscription.unsubscribe();
    }
    if (this.geolocationSubscription) {
      this.geolocationSubscription.unsubscribe();
    }
  }

  cancel() {
    this.activeModal.dismiss();
  }

  goCreateAccount() {
    this.activeModal.dismiss();
    window.location.href = environment.urlRoot + '/create-account' + window.location.search;
  }

  goResetPassword() {
    this.activeModal.dismiss();
    window.location.href = environment.urlRoot + '/reset' + window.location.search;
  }

  onAuthProviderActionChange(response: SignInResponse) {

    // If status is OK, we are signed in!
    if (response.Status === 'OK') {
      this.activeModal.close(response.SessionInfo);
    }

    this.ngZone.run(() => {

      // We need to continue to other stages, turn on animations
      this.isAnimationPaused = false;

      // Set the credentials
      this.credentials.AuthProvider = response.AuthProvider;
      this.credentials.Email = response.Email;
      this.credentials.FirstName = response.FirstName;
      this.credentials.LastName = response.LastName;
      this.credentials.Token = response.Token;

      // Set the correct stage
      this.stage = response.Status;
    });
  }

  resendVerificationEmail() {

    // Immediately leave if we're already resending
    if (this.isResendingVerification) {
      return;
    }

    // Show spinner
    this.isResendingVerification = true;

    // Reset the status
    this.errorResendingVerification = '';
    this.isVerificationResent = false;

    // Call the web service
    this.authHttpService.resendVerificationEmail(this.credentials.Email)
      .subscribe(
        () => {

          // Show message
          this.isVerificationResent = true;

          // Hide spinner
          this.isResendingVerification = false;
        },
        (error) => {

          // Set the error
          this.errorResendingVerification = error.error;

          // Hide spinner
          this.isResendingVerification = false;
        }
      );
  }

  submit() {

    // Store / delete email from local storage
    if (this.rememberEmail) {
      this.localStorageService.setItem(this.emailKey, this.credentials.Email);
    } else {
      this.localStorageService.removeItem(this.emailKey);
    }

    // Set status to wait
    this.status = 'WAIT';

    // If timezone is resolvable, pass it with credentials
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone) {
      this.credentials.Timezone = timezone;
    }

    switch (this.stage) {
      case 'MERGE-ACCOUNTS':

        // Send credentials to backend, close dialog if success
        return this.authHttpService.linkCredentials(this.credentials)
          .subscribe(
            response => this.activeModal.close(response),
            error => this.status = error.error
          );

      case 'SIGN-UP':

        // Send credentials to backend, close dialog if success
        return this.authHttpService.createAccount(this.credentials)
          .subscribe(
            response => this.activeModal.close(response),
            error => this.status = error.error
          );

      default:

        // Send credentials to backend, close dialog if success
        return (this.loginMode === 'user' ? this.authHttpService.signIn(this.credentials) : this.authHttpService.signInStudent(this.credentials))
          .subscribe(
            response => this.activeModal.close(response),
            error => this.status = error.error
          );
    }
  }

  switchLogin(mode: string) {
    this.loginMode = mode;
  }

  // Render the Google Sign-in button
  private renderGoogleSignInButton() {

    this.googleSignInService.renderButton(this.googleSignInButton);
  }
}
