import { animate, group, state, style, trigger, transition } from '@angular/animations';

export const slideInOut = trigger('slideInOut', [
  state('in', style({height: '*', opacity: 0})),
  transition(':leave', [
    style({height: '*', opacity: 1}),
    group([
      animate(150, style({height: 0})),
      animate('150ms ease-out', style({'opacity': '0'}))
    ])
  ]),
  transition(':enter', [
    style({height: '0', opacity: 0}),
    group([
      animate(150, style({height: '*'})),
      animate('150ms ease-in', style({'opacity': '1'}))
    ])
  ])
]);
