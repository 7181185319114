<!--div class="d-none d-lg-block announcement-bar">
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <a class="cwk-link cwk-white d-block" href="{{ urlRoot + '/online-enrichment-classes' }}" id="announcement-bar-left-link" tm-destination="/online-enrichment-classes">Online Enrichment Classes: REGISTER NOW</a>
      <a class="cwk-link cwk-white d-block" href="{{ urlRoot + '/online-camps' }}" id="announcement-bar-left-link" tm-destination="/online-camps">Online Summer Camps: REGISTER NOW</a>
    </div>
    <div class="col-md-12 col-lg-6 right-col">
      <a class="cwk-link cwk-white d-block" href="{{ urlRoot + '/online-enrichment-classes' }}" id="announcement-bar-right-link" tm-destination="/online-enrichment-classes">Online Enrichment Classes: REGISTER NOW</a>
      <a class="cwk-link cwk-white d-block" href="{{ urlRoot + '/camps/seatte_pr' }}" id="announcement-bar-right-link" tm-destination="/camps/seattle_pr">Seattle In-Person Camps: REGISTER NOW</a>
      <a class="cwk-link cwk-white d-block" href="{{ urlRoot + '/private-classes' }}" id="announcement-bar-right-link" tm-destination="/private-classes">Online Private Classes: REGISTER NOW</a>
      <a class="cwk-link cwk-white d-block" href="{{ urlRoot + '/online-camps' }}" id="announcement-bar-right-link" tm-destination="/online-camps">Online Summer Camps: REGISTER NOW</a>
    </div>
  </div>
</div-->
<div class="page-header">

  <!-- ENVIRONMENT NAME -->
  <div class="env-title text-center" *ngIf="environmentName">{{ environmentName }}</div>

  <div class="d-flex justify-content-between align-items-center px-3">

    <div class="cwk-pointer logo">
      <a id="cwk-logo" href="{{ urlRoot + '/' }}" tm-destination="/"><img src="https://d3t4xfu733v2tb.cloudfront.net/logo/logo_200x120_white_yelllow_transparent-r.png" alt="Coding with Kids Logo" width="200" height="107"/></a>
    </div>

    <!-- HEADER MENU -->
    <div class="header-menu w-100">
      <ng-content></ng-content>
    </div>
  </div>
</div>
