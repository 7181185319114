import { CookieConsentCompliance } from '../enums/cookie-consent-compliance';
import { CookieConsentPrivacy } from '../enums/cookie-consent-privacy';

const DEFAULT_COOKIE_CONSENT_COMPLIANCE = CookieConsentCompliance.HIDDEN;

export class CookieConsentLaw {
  Compliance: CookieConsentCompliance;
  Country: string;
  Privacy: CookieConsentPrivacy;
  Region: string;

  constructor(country: string, region: string, compliance: CookieConsentCompliance, privacy: CookieConsentPrivacy) {
    this.Compliance = compliance;
    this.Country = country;
    this.Privacy = privacy;
    this.Region = region;
  }

  static getCompliance(country: string, region: string) {
    return CookieConsentLaws.find(law => (law.Country === country) && (!law.Region || (law.Region === region)))?.Compliance || DEFAULT_COOKIE_CONSENT_COMPLIANCE;
  }
}

export const CookieConsentLaws: CookieConsentLaw[] = [
  new CookieConsentLaw('AT', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('BE', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('BG', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('CA', null, CookieConsentCompliance.OPT_OUT, CookieConsentPrivacy.PIPEDA),
  new CookieConsentLaw('CY', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('CZ', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('DE', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('DK', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('EE', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('EL', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('ES', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('EU', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('FI', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('FR', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('GB', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('GR', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('HR', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('HU', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('IE', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('IT', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('LV', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('LT', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('LU', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('MT', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('NL', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('PL', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('PT', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('SE', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('SK', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('UK', null, CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.GDPR),
  new CookieConsentLaw('US', 'CA', CookieConsentCompliance.OPT_IN, CookieConsentPrivacy.CCPA)
];
