import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CookieCategory, CookieInfo, CookieInfos } from '../../classes/cookie-info';
import { CookieConsentData } from '../../interfaces/cookie-consent-data';
import { slideInOut } from '@shared/animations/slide-in-out';

@Component({
  animations: [slideInOut],
  selector: 'shared-dialog-cookie-consent',
  templateUrl: './dialog-cookie-consent.component.html',
  styleUrls: ['./dialog-cookie-consent.component.scss']
})
export class DialogCookieConsentComponent implements OnInit {

  @Input() consents: CookieConsentData;

  // Model
  cookiesAdvertising: CookieInfo[] = null;
  cookiesAnalytics: CookieInfo[] = null;
  cookiesEssential: CookieInfo[] = null;
  isCookiesAdvertisingShown = false;
  isCookiesAnalyticsShown = false;
  isCookiesEssentialShown = false;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    // Prepare cookie information
    this.cookiesAdvertising = CookieInfos.filter(cookie => cookie.Category === 'Advertising');
    this.cookiesAnalytics = CookieInfos.filter(cookie => cookie.Category === 'Analytics');
    this.cookiesEssential = CookieInfos.filter(cookie => cookie.Category === 'Essential');
  }

  saveAgreeAll() {

    // Update consents
    this.consents.AdPersonalization = true;
    this.consents.AdStorage = true;
    this.consents.AdUserData = true;
    this.consents.AnalyticsStorage = true;
    this.consents.FunctionalityStorage = true;
    this.consents.PersonalizationStorage = true;
    this.consents.SecurityStorage = true;

    // Close the dialog
    this.activeModal.close();
  }

  saveChoices() {

    // Consents have been modified, just close the dialog
    this.activeModal.close();
  }

  saveRejectAll() {

    // Update consents
    this.consents.AdPersonalization = false;
    this.consents.AdStorage = false;
    this.consents.AdUserData = false;
    this.consents.AnalyticsStorage = false;
    this.consents.FunctionalityStorage = true;
    this.consents.PersonalizationStorage = false;
    this.consents.SecurityStorage = false;

    // Close the dialog
    this.activeModal.close();
  }

  switchCookiesAdvertisingInfo() {
    this.isCookiesAdvertisingShown = !this.isCookiesAdvertisingShown;
  }

  switchCookiesAnalyticsInfo() {
    this.isCookiesAnalyticsShown = !this.isCookiesAnalyticsShown;
  }

  switchCookiesEssentialInfo() {
    this.isCookiesEssentialShown = !this.isCookiesEssentialShown;
  }
}
