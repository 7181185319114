import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerFeedbackRatingDataService {

  constructor(
    private http: HttpClient
  ) { }

  private urlCustomerFeedback = environment.urlWebServices + '/cwk/customerFeedback';

  readInfo(): Observable<any> {

    // Call web service
    const getURL = `${this.urlCustomerFeedback}/averageInfo`;
    return this.http.get<any>(getURL)
      .pipe(
        catchError(error => observableThrowError(error.message || error))
      );
  }
}
