// This is based on https://stackoverflow.com/questions/5639346/what-is-the-shortest-function-for-reading-a-cookie-by-name-in-javascript?rq=1
// If more functionality is needed, it should be replaced with some Angular library.
// As of now (2021-07-06), the existing most used libraries don't work with Angular 8 and 9 at the same time so we need to upgrade our applications first.
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  read(name: String) {
    const matches = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return (matches && matches.length) ? matches.pop() || null : null;
  }
}
