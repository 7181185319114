<!-- DESKTOP MENU -->
<div class="d-none d-md-block">
  <ul class="header-menu nav nav-pills justify-content-end flex-column flex-sm-row align-items-center">

    <!-- HOME -->
    <li class="home nav-item">
      <small [hidden]="organization || !errorReadingOrganization">
        <span class="nav-link" [ngClass]="{'active': activeTab === 'HOME'}">
          <fa-icon *ngIf="!errorReadingOrganization" [icon]="faSpinner" [spin]="true"></fa-icon>
          <fa-icon *ngIf="errorReadingOrganization" [icon]="faExclamationTriangle" ngbPopover="{{errorReadingOrganization}}" triggers="mouseenter:mouseleave" placement="auto"></fa-icon>
        </span>
      </small>
      <div [hidden]="!organization || errorReadingOrganization">
        <a class="nav-link" [ngClass]="{'active': activeTab === 'HOME'}" href="{{ urlRoot + organization?.HomeLink }}" id="home-menu-item" tm-destination="/" i18n>
          <b *ngIf="organization?.OrganizationId !== CWK_ORGANIZATION_ID">
            <fa-icon class="me-2" [icon]="faMapMarkerAlt"></fa-icon>{{ organization?.Name }}
          </b>
          <span *ngIf="organization?.OrganizationId === CWK_ORGANIZATION_ID">Home</span>
        </a>
      </div>
    </li>

    <!-- PROGRAMS & REGISTRATIONS -->
    <li class="nav-item" ngbDropdown>
      <span class="nav-link" [ngClass]="{'active': activeTab === 'REGISTRATIONS'}" ngbDropdownToggle role="button" aria-haspopup="true" aria-expanded="false" i18n>Programs</span>
      <div class="dropdown-menu programs-registrations-menu" ngbDropdownMenu>

        <a href="{{ urlRoot + '/coders-ladder-group-classes' }}" id="coders-ladder-schedule-classes" tm-destination="/coders-ladder-group-classes" class="menu-link-item menu-link-open" i18n>Group Classes</a>

        <a href="{{ urlRoot + '/private-classes' }}" id="private-classes-schedule-classes" tm-destination="/private-classes" class="menu-link-item menu-link-open" i18n>Private Classes</a>

        <ng-container *ngFor="let info of campMenuInfo">
          <a href="{{ urlRoot + info.URL }}" [id]="info.TelemetryId" [attr.tm-destination]="info.TelemetryDestination" class="menu-link-item menu-link-open" i18n>{{ info.DisplayName }}</a>
        </ng-container>
        <a href="{{ urlRoot + '/online-camps' }}" id="online-camps" tm-destination="/online-camps" class="menu-link-item menu-link-open" i18n *ngIf="campMenuInfo && !campMenuInfo.length">Camps</a>

        <a href="{{ urlRoot + '/in-person-enrichment-classes' }}" id="coders-pathways-schedule-classes" tm-destination="/in-person-enrichment-classes" class="menu-link-item menu-link-open" i18n>Classes at Schools</a>

      </div>
    </li>

    <!-- CODING PROGRAMS FOR SCHOOLS -->
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': activeTab === 'SCHOOLS'}" href="{{ urlRoot + '/coding-programs-for-schools' }}" id="coding-programs-for-schools" tm-destination="/coding-programs-for-schools" i18n>For&nbsp;Schools</a>
    </li>

    <!-- ABOUT US -->
    <li class="nav-item" ngbDropdown>
      <span class="nav-link" [ngClass]="{'active': activeTab === 'ABOUT-US'}" ngbDropdownToggle role="button" aria-haspopup="true" aria-expanded="false" i18n>About Us</span>
      <div class="dropdown-menu" ngbDropdownMenu>
        <a href="{{ urlRoot + '/history' }}" id="about-us-history" tm-destination="/history" class="menu-link-item menu-link-open" i18n>History&nbsp;&amp;&nbsp;Mission</a>
        <a href="{{ urlRoot + '/management' }}" id="about-us-management" tm-destination="/management" class="menu-link-item menu-link-open" i18n>Leadership&nbsp;team</a>

        <!-- CURRICULUM -->
        <span class="menu-link-open">
          <div class="nested-drop">
            <div class="d-none d-md-block item dropdown dropstart">
              <div class="dropdown-toggle menu-link-item" data-toggle="dropdown">Curriculum</div>
              <div class="dropdown-menu shadow-lg">
                <a href="{{ urlRoot + '/coders-ladder' }}" id="curriculum-coders-ladder" tm-destination="/coders-ladder" class="menu-link-item menu-link-open" i18n>Coder's&nbsp;Ladder<span class="cwk-registration-icon">&reg;</span></a>
                <a href="{{ urlRoot + '/coders-pathways' }}" id="curriculum-coders-pathways" tm-destination="/coders-pathways" class="menu-link-item menu-link-open" i18n>Coder's&nbsp;Pathways<span class="cwk-registration-icon">&reg;</span></a>
              </div>
            </div>
          </div>
        </span>

        <a href="{{ urlRoot + '/our-students' }}" id="about-us-our-students" tm-destination="/our-students" class="menu-link-item menu-link-open" i18n>Our&nbsp;Students</a>

        <span class="menu-link-open">
          <div class="nested-drop">
            <div class="d-none d-md-block item dropdown dropstart">
              <div class="dropdown-toggle menu-link-item" data-toggle="dropdown">Locations</div>
              <div class="dropdown-menu shadow-lg">
                <cwk-locations-menu></cwk-locations-menu>
              </div>
            </div>
          </div>
        </span>

        <a href="{{ urlRoot + '/blog' }}" id="about-us-blog" tm-destination="/blog" class="menu-link-item menu-link-open" i18n>Blog</a>
        <a href="{{ urlRoot + '/jobs' }}" id="about-us-jobs" tm-destination="/jobs" class="menu-link-item menu-link-open" i18n>Jobs</a>
        <a href="{{ urlRoot + '/contact' }}" id="about-us-contact" tm-destination="/contact" class="menu-link-item menu-link-open" i18n>Contact&nbsp;Us</a>
        <a href="{{ urlRoot + '/faqs' }}" id="about-us-faqs" tm-destination="/faqs" class="menu-link-item menu-link-open" i18n>FAQs</a>
      </div>
    </li>

    <!-- INTERNAL -->
    <li *ngIf="isInternal()" class="nav-item" ngbDropdown (click)="readUpcomingLesson()">
      <span class="nav-link" [ngClass]="{'active': activeTab === 'INTERNAL'}" ngbDropdownToggle role="button" aria-haspopup="true" aria-expanded="false" i18n>Internal</span>
      <div class="dropdown-menu" ngbDropdownMenu>
        <a *ngIf="isManager()" class="menu-link-item menu-link-open" href="{{ urlRoot + '/web/#!/manager' }}" i18n>Manager&nbsp;Dashboard</a>
        <a *ngIf="isManager()" class="menu-link-item menu-link-open" href="{{ urlRoot + '/dashboard/operations' }}" i18n>Operations&nbsp;Dashboard</a>
        <a *ngIf="isStaffTraining()" class="menu-link-item menu-link-open" href="{{ urlRoot + '/admin/employees' }}" i18n>Employees&nbsp;Page</a>
        <a *ngIf="isTimesheet()" class="menu-link-item menu-link-open" href="{{ urlRoot + '/time/' + (isPayroll() ? '#/payroll' : '#/manage') }}" i18n>{{ isPayroll() ? 'Manage&nbsp;Payroll' : 'Timesheet&nbsp;Approval' }}</a>
        <a *ngIf="isRecruiting() && isLicensed('RECRUITING')" class="menu-link-item menu-link-open" href="{{ urlRoot + '/admin/recruiting' }}" i18n>Recruiting&nbsp;Page</a>
        <a *ngIf="isAdminPage()" class="menu-link-item menu-link-open" href="{{ urlRoot + '/web/#!/admin' }}" i18n>Admin&nbsp;Page</a>
        <a *ngIf="isInstructor() || isManager()" class="menu-link-item menu-link-open" href="{{ urlRoot + '/instructor/' }}" i18n>Instructor&nbsp;Dashboard</a>
        <a *ngIf="isTimeTracking() && isLicensed('TIME-TRACKING')" class="menu-link-item menu-link-open" href="{{ urlRoot + '/time/' }}" i18n>Time&nbsp;Tracking</a>
        <a *ngIf="isInstructor() && upcomingLesson" class="menu-link-item menu-link-open" href="{{ urlRoot + '/web/#!/student-lesson-unit?guid=' + upcomingLesson.GUID }}" i18n>Upcoming Lesson</a>
      </div>
    </li>

    <!-- ACCOUNT (ACCESSIBLE ONLY TO SIGNED-IN USERS) -->
    <li class="nav-item" *ngIf="isSignedIn()">
      <a class="nav-link" [ngClass]="{'active': activeTab === 'ACCOUNT'}" href="{{ urlRoot + '/account' }}" i18n>Account</a>
    </li>

    <!-- CURRENCY SELECTOR -->
    <cwk-currency-selector></cwk-currency-selector>
  </ul>
</div>

<!-- MOBILE MENU -->
<div class="d-block d-md-none">
  <div class="d-flex justify-content-end header-menu-mobile pt-1">

    <div class="cwk-white me-3 pt-1">
      <cwk-sign-in-out *ngIf="!isSignedIn()"></cwk-sign-in-out>
      <span *ngIf="isSignedIn()" class="cwk-pointer">
        <span *ngIf="!isInternal()" (click)="goUrl('/account')">My Account</span>
        <span class="ms-3" (click)="signOut()">Logout</span>
      </span>
    </div>

    <!-- Currency selector -->
    <cwk-currency-selector class="me-4 pt-1" mode="MOBILE"></cwk-currency-selector>

    <ul ngbNav>

      <!-- MOBILE MENU -->
      <li ngbDropdown ngbNavItem>
        <span class="cwk-white" ngbDropdownToggle role="button" aria-haspopup="true" aria-expanded="false" i18n>

          <!-- MENU BARS -->
          <fa-icon class="fs-1" (click)="switchMobileMenu()" [icon]="faBars"></fa-icon>
        </span>
        <div class="mobile-dropdown-menu" ngbDropdownMenu>

          <a href="#" class="menu-link-open menu-link-section" [disabled]="true" ngbDropdownItem>Programs</a>
          <a href="{{ urlRoot + '/coders-ladder-group-classes' }}" id="coders-ladder-schedule-classes" tm-destination="/coders-ladder-group-classes" class="menu-link-section-item menu-link-open" i18n ngbDropdownItem>Group Classes</a>
          <a href="{{ urlRoot + '/private-classes' }}" id="private-classes-schedule-classes" tm-destination="/private-classes" class="menu-link-section-item menu-link-open" i18n ngbDropdownItem>Private Classes</a>
          <ng-container *ngFor="let info of campMenuInfo">
            <a href="{{ urlRoot + info.URL }}" [id]="info.TelemetryId" [attr.tm-destination]="info.TelemetryDestination" class="menu-link-section-item menu-link-open" i18n>{{ info.DisplayName }}</a>
          </ng-container>
          <a href="{{ urlRoot + '/in-person-enrichment-classes' }}" id="coders-pathways-schedule-classes" tm-destination="/in-person-enrichment-classes" class="menu-link-section-item menu-link-open" i18n ngbDropdownItem>Classes at Schools</a>
          <a href="#" class="menu-link-open menu-link-section" [disabled]="true" ngbDropdownItem>For Schools</a>
          <a href="{{ urlRoot + '/coding-programs-for-schools' }}" id="coding-programs-for-schools" tm-destination="/coding-programs-for-schools" class="menu-link-section-item menu-link-open" i18n ngbDropdownItem>Overview</a>
          <a href="#" class="menu-link-open menu-link-section" [disabled]="true" ngbDropdownItem>About Us</a>
          <a href="{{ urlRoot + '/history' }}" id="about-us-history" tm-destination="/history" class="menu-link-section-item menu-link-open" i18n ngbDropdownItem>History&nbsp;&amp;&nbsp;Mission</a>
          <a href="{{ urlRoot + '/management' }}" id="about-us-management" tm-destination="/management" class="menu-link-section-item menu-link-open" i18n ngbDropdownItem>Leadership&nbsp;Team</a>
          <a href="{{ urlRoot + '/coders-ladder' }}" id="curriculum-coders-ladder" tm-destination="/coders-ladder" class="menu-link-section-item menu-link-open" i18n>Coder's&nbsp;Ladder<span class="cwk-registration-icon">&reg;</span></a>
          <a href="{{ urlRoot + '/coders-pathways' }}" id="curriculum-coders-pathways" tm-destination="/coders-pathways" class="menu-link-section-item menu-link-open" i18n>Coder's&nbsp;Pathways<span class="cwk-registration-icon">&reg;</span></a>
          <a href="{{ urlRoot + '/our-students' }}" id="about-us-our-students" tm-destination="/our-students" class="menu-link-section-item menu-link-open" i18n ngbDropdownItem>Our&nbsp;Students</a>
          <a href="{{ urlRoot + '/blog' }}" id="about-us-blog" tm-destination="/blog" class="menu-link-section-item menu-link-open" i18n>Blog</a>
          <a href="{{ urlRoot + '/jobs' }}" id="about-us-jobs" tm-destination="/jobs" class="menu-link-section-item menu-link-open" i18n>Jobs</a>
          <a href="{{ urlRoot + '/contact' }}" id="about-us-contact" tm-destination="/contact" class="menu-link-section-item menu-link-open" i18n>Contact&nbsp;Us</a>
          <a href="{{ urlRoot + '/faqs' }}" id="about-us-faqs" tm-destination="/faqs" class="menu-link-section-item menu-link-open" i18n>FAQs</a>
          <a *ngIf="isInternal()" href="#" class="menu-link-open menu-link-section" [disabled]="true" ngbDropdownItem>Internal</a>
          <a *ngIf="isManager()" class="menu-link-section-item menu-link-open" href="{{ urlRoot + '/web/#!/manager' }}" i18n ngbDropdownItem>Manager&nbsp;Dashboard</a>
          <a *ngIf="isManager()" class="menu-link-section-item menu-link-open" href="{{ urlRoot + '/dashboard/operations' }}" i18n ngbDropdownItem>Operations&nbsp;Dashboard</a>
          <a *ngIf="isStaffTraining()" class="menu-link-section-item menu-link-open" href="{{ urlRoot + '/admin/employees' }}" i18n ngbDropdownItem>Employees&nbsp;Page</a>
          <a *ngIf="isTimesheet()" class="menu-link-section-item menu-link-open" href="{{ urlRoot + '/time/' + (isPayroll() ? '#/payroll' : '#/manage') }}" i18n ngbDropdownItem>{{ isPayroll() ? 'Manage&nbsp;Payroll' : 'Timesheet&nbsp;Approval' }}</a>
          <a *ngIf="isRecruiting() && isLicensed('RECRUITING')" class="menu-link-section-item menu-link-open" href="{{ urlRoot + '/admin/recruiting' }}" i18n ngbDropdownItem>Recruiting&nbsp;Page</a>
          <a *ngIf="isAdminPage()" class="menu-link-section-item menu-link-open" href="{{ urlRoot + '/web/#!/admin' }}" i18n ngbDropdownItem>Admin&nbsp;Page</a>
          <a *ngIf="isInstructor() || isManager()" class="menu-link-section-item menu-link-open" href="{{ urlRoot + '/instructor/' }}" i18n ngbDropdownItem>Instructor&nbsp;Dashboard</a>
          <a *ngIf="isTimeTracking() && isLicensed('TIME-TRACKING')" class="menu-link-section-item menu-link-open" href="{{ urlRoot + '/time/' }}" i18n ngbDropdownItem>Time&nbsp;Tracking</a>
          <a *ngIf="isInstructor() && upcomingLesson" class="menu-link-section-item menu-link-open" href="{{ urlRoot + '/web/#!/student-lesson-unit?guid=' + upcomingLesson.GUID }}" i18n ngbDropdownItem>Upcoming Lesson</a>
        </div>
      </li>

    </ul>
  </div>
</div>
