import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class LocalStorageService {

  private storage = localStorage;

  constructor() { }

  clear() {
    return this.storage.clear();
  }

  getItem(key) {
    return this.storage.getItem(environment.localStoragePrefix + key);
  }

  removeItem(key) {
    return this.storage.removeItem(environment.localStoragePrefix + key);
  }

  setItem(key, value) {
    return this.storage.setItem(environment.localStoragePrefix + key, value);
  }
}

