import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomepageDataService {

  constructor(
    private http: HttpClient
  ) { }

  private urlHomepage = environment.urlWebServices + '/api/homepage';

  readInit(): Observable<any> {

    // Call web service
    const getURL = `${this.urlHomepage}`;
    return this.http.get<any>(getURL)
      .pipe(
        catchError(error => observableThrowError(error.message || error))
      );
  }
}
