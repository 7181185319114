import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { Telemetry } from '../interfaces/telemetry';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TelemetryDataService {

  private urlTelemetry = environment.urlWebServices + '/api/telemetry';

  constructor(private http: HttpClient) { }

  // Save the Telemetry data
  create(payload: Telemetry): Observable<any> {
    return this.http.post(this.urlTelemetry, payload)
      .pipe(catchError(error => observableThrowError(error.message || error)));
  }
}
