import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable()
export class DialogJoinMailingListDataService {

  private urlSubscriber = environment.urlWebServices + '/api/subscriber';

  constructor(private http: HttpClient) { }

  subscribe(mailingListInfo: any): Observable<any> {

    // Call web service
    return this.http.post(`${this.urlSubscriber}`, mailingListInfo)
      .pipe(catchError(error => observableThrowError(error.message || error)));
  }
}
