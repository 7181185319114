<form class="form" #subscribeForm="ngForm" [ngClass]="{'pb-3' : isSaved}">

  <!-- NOT SAVED, SHOW THE FORM -->
  <div *ngIf="!isSaved">
    <h4 *ngIf="!extraSuccessMessage && !hideTitle" class="heading">{{ title }}</h4>
    <div class="row align-items-center">
      <div class="col-md-6 col-lg-5 form-group mt-1">

        <!-- FIRST NAME -->
        <!--label for="firstName" i18n>First Name:</label-->
        <input type="text" class="form-control form-control-lg" name="firstName" [ngClass]="{'cwk-form-control-required': !mailingListInfo.FirstName}" [(ngModel)]="mailingListInfo.FirstName" i18n-placeholder placeholder="First name" #email="ngModel" required>
      </div>
      <div class="col-md-6 col-lg-5 form-group mt-1">
        <input type="email" class="form-control form-control-lg" name="email" [ngClass]="{'cwk-form-control-email': mailingListInfo.Email && email.errors && (email.dirty || email.touched) && email.errors['email'], 'cwk-form-control-required': !mailingListInfo.Email}" [(ngModel)]="mailingListInfo.Email" i18n-placeholder placeholder="Email" #email="ngModel" email required>
      </div>
      <!--div class="col-lg-2 form-group mt-1">
        <input type="text" class="form-control" name="lastName" [ngClass]="{'cwk-form-control-required': !mailingListInfo.LastName}" [(ngModel)]="mailingListInfo.LastName" placeholder="Last name" #email="ngModel" required>
      </div>
      <div class="col-lg-2 form-group mt-1">
        <input type="email" class="form-control" name="email" [ngClass]="{'cwk-form-control-email': mailingListInfo.Email && email.errors && (email.dirty || email.touched) && email.errors.email, 'cwk-form-control-required': !mailingListInfo.Email}" [(ngModel)]="mailingListInfo.Email" placeholder="Email" #email="ngModel" email required>
      </div>
      <div class="col-lg-2 form-group mt-1">
        <div>
          <input type="text" class="form-control" [ngClass]="{'cwk-form-control-required' : !countryExists()}" autocomplete='new-password' [inputFormatter]="formatCountry" [(ngModel)]="country" name="country" [ngbTypeahead]="searchCountry" placeholder="Start typing..." [resultFormatter]="formatCountry"/>
        </div>
      </div>
      <div class="col-lg-2 form-group mt-1">
        <input type="text" class="form-control" name="zip" [ngClass]="{'cwk-form-control-error-zip': mailingListInfo.ZIP && zip.errors && (zip.dirty || zip.touched), 'cwk-form-control-required': !mailingListInfo.ZIP}" [(ngModel)]="mailingListInfo.ZIP" [placeholder]="country?.Code === 'US' ? 'Zip code' : 'Postal code'" #zip="ngModel" [pattern]="country?.Code === 'US' ? '[0-9]{5,6}' : ''" required>
      </div-->
      <div class="col-lg-2 mt-1 mb-3">
        <button *ngIf="!isSaved" type="button" class="btn btn-secondary d-flex justify-content-center w-100" [disabled]="isSaving || !isFormValid()" (click)="submit()">
          <span *ngIf="isSaving; else join"><fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>&nbsp;<ng-container i18n>Subscribing...</ng-container></span>
          <ng-template #join><div id="join-mailing-list" i18n>Sign up</div></ng-template>
        </button>
      </div>
    </div>
    <!--div class="footer">(Parents who have signed up previously do not need to sign up again.)</div-->
  </div>

  <!-- ERROR SAVING -->
  <ngb-alert *ngIf="errorSaving" class="mt-3" type="danger" (close)="errorSaving = null">
    <b><fa-icon [icon]="faExclamationTriangle"></fa-icon>&nbsp;<ng-container i18n>Error occurred while saving:</ng-container></b>&nbsp;{{errorSaving}}
  </ngb-alert>

  <!-- SAVED, SHOW SUCCESS MESSAGE -->
  <div *ngIf="isSaved" class="alert alert-success mb-0" role="alert">
    <div i18n>Thank you! You have subscribed to our mailing list.</div>
    <div *ngIf="extraSuccessMessage" class="extra-success-message" [innerHTML]="extraSuccessMessage"></div>
  </div>
</form>
