import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageHitDataService {

  private urlPageHit = environment.urlWebServices + '/cwk/pageHit';

  constructor(private http: HttpClient) { }

  // Create new page hit record
  create(data: any): Observable<any> {
    return this.http.post(`${this.urlPageHit}`, data)
      .pipe(
        catchError(error => observableThrowError(error.message || error))
      );
  }
}
