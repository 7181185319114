<form ngForm #subscribeForm>
  <div class="modal-header">
    <h2 i18n>Join Our Mailing List</h2>
  </div>

  <!-- BODY -->
  <div class="modal-body">

    <!-- NOT SAVED, SHOW THE FORM -->
    <div *ngIf="!isSaved">
      <h4 class="cwk-grey">Get company news and notifications about our classes and camps</h4>
      <div class="row">
        <div class="col-12 col-sm-6 form-group mt-2">

          <!-- FIRST NAME -->
          <label for="firstName" i18n>First Name:</label>
          <input type="text" class="form-control" name="firstName" [ngClass]="{'cwk-form-control-required': !mailingListInfo.FirstName}" [(ngModel)]="mailingListInfo.FirstName" placeholder="First name" #email="ngModel" required>
        </div>
        <div class="col-12 col-sm-6 form-group mt-2">

          <!-- LAST NAME -->
          <label for="lastName" i18n>Last Name:</label>
          <input type="text" class="form-control" name="lastName" [ngClass]="{'cwk-form-control-required': !mailingListInfo.LastName}" [(ngModel)]="mailingListInfo.LastName" placeholder="Last name" #email="ngModel" required>
        </div>
        <div class="col form-group mt-2">

          <!-- EMAIL -->
          <label for="email" i18n>Email:</label>
          <input type="email" class="form-control" name="email" [ngClass]="{'cwk-form-control-email': mailingListInfo.Email && email.errors && (email.dirty || email.touched) && email.errors.email, 'cwk-form-control-required': !mailingListInfo.Email}" [(ngModel)]="mailingListInfo.Email" placeholder="Email" #email="ngModel" email required>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 form-group mt-2">

          <!-- COUNTRY -->
          <label for="country" i18n>Country:</label>
          <div>
            <input type="text" class="form-control" [ngClass]="{'cwk-form-control-required' : !countryExists()}" autocomplete='new-password' [inputFormatter]="formatCountry" [(ngModel)]="country" name="country" [ngbTypeahead]="searchCountry" placeholder="Start typing..." [resultFormatter]="formatCountry"/>
          </div>
        </div>
        <div class="col-12 col-sm-6 form-group mt-2">

          <!-- ZIP -->
          <label for="zip" i18n>{{ country?.Code === 'US' ? 'Zip Code' : 'Postal Code' }}</label>
          <input type="text" class="form-control" name="zip" [ngClass]="{'cwk-form-control-error-zip': mailingListInfo.ZIP && zip.errors && (zip.dirty || zip.touched), 'cwk-form-control-required': !mailingListInfo.ZIP}" [(ngModel)]="mailingListInfo.ZIP" [placeholder]="country?.Code === 'US' ? 'XXXXX' : 'Postal code...'" #zip="ngModel" [pattern]="country?.Code === 'US' ? '[0-9]{5,6}' : ''" required>
        </div>
      </div>
    </div>

    <!-- ERROR SAVING -->
    <ngb-alert *ngIf="errorSaving" class="mt-3" type="danger" (close)="errorSaving = null">
      <b><fa-icon [icon]="faExclamationTriangle"></fa-icon>&nbsp;Error occurred while saving:</b>&nbsp;{{errorSaving}}
    </ngb-alert>

    <!-- SAVED, SHOW SUCCESS MESSAGE -->
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      You have subscribed to our mailing list!
    </div>
  </div>

  <!-- FOOTER -->
  <div class="modal-footer">
    <button *ngIf="!isSaved" type="button" class="btn btn-warning btn-large mr-auto" (click)="cancel()" i18n>Cancel</button>
    <button *ngIf="!isSaved" type="button" class="btn btn-warning btn-large" [disabled]="isSaving || !isFormValid()" (click)="submit()" i18n>
      <span *ngIf="isSaving; else join"><fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>&nbsp;Subscribing...</span>
      <ng-template #join>Join Mailing List</ng-template>
    </button>
    <button *ngIf="isSaved" type="button" class="btn btn-warning btn-large pull-right" (click)="close()">Close</button>
  </div>
</form>
