import { Injectable } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';

import { Observable } from 'rxjs';

@Injectable()
export class GeocodeService {

  constructor(private geocoder: MapGeocoder) {}

  geocodeAddress(location: string): Observable<any> {
    return new Observable(observer => {
      this.geocoder.geocode({'address': location})
        .subscribe(
          ({ results }) => {
            observer.next({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            });
            observer.complete();
          },
          (error) => {
            observer.next({ lat: 0, lng: 0 });
            observer.complete();
          }
        );
      });
  }

  /*geocodeAddress(location: string): Observable<any> {
    return this.waitForMapsToLoad().pipe(
      switchMap(() => {
        return new Observable(observer => {
          this.geocoder.geocode({'address': location}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
              });
            } else {
              observer.next({ lat: 0, lng: 0 });
            }
            observer.complete();
          });
        });
      })
    );
  }

  private initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return from(this.mapLoader.load())
      .pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }*/
}
