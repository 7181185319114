import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  private storage = sessionStorage;

  // We use environment.localStoragePrefix because the session storage is temporary only and
  // keys never collide anyway in both local and session storages

  constructor() { }

  getItem(key) {
    return this.storage.getItem(environment.localStoragePrefix + key);
  }

  // Session storage items should never be removed explicitly
  // Browser removes them automatically when the session is over (tab or browser is closed)
  removeItem(key) {
    return this.storage.removeItem(environment.localStoragePrefix + key);
  }

  setItem(key, value) {
    return this.storage.setItem(environment.localStoragePrefix + key, value);
  }
}
