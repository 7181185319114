import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-teaching-philosophy',
  templateUrl: './teaching-philosophy.component.html',
  styleUrls: ['./teaching-philosophy.component.scss']
})
export class TeachingPhilosophyComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}

