export type CookieCategory = 'Essential' | 'Analytics' | 'Advertising';

export class CookieInfo {

  Category: CookieCategory;
  Name: string;
  UsedBy: string;
  Description: string;

  constructor(category: CookieCategory, name: string, usedBy: string, description: string) {
    this.Category = category;
    this.Description = description;
    this.Name = name;
    this.UsedBy = usedBy;
  }
}

export const CookieInfos: CookieInfo[] = [
  new CookieInfo('Analytics', '_fbp', 'Facebook', 'to store and track visits across websites'),
  new CookieInfo('Analytics', '_ga', 'Google Analytics', 'to store and count pageviews'),
  new CookieInfo('Analytics', '_ga_*', 'Google Analytics', 'to store and count pageviews'),
  new CookieInfo('Analytics', '_gat_UA-*', 'Google Analytics', 'to provide technical monitoring'),
  new CookieInfo('Analytics', '_gcl_au', 'Google Adsense', 'to store and track conversions'),
  new CookieInfo('Analytics', '_gid', 'Google Analytics', 'to store and count pageviews'),
  new CookieInfo('Analytics', 'vuid', 'Vimeo', 'to store the user\'s usage history'),
  new CookieInfo('Advertising', '_fbp', 'Facebook', 'to store and track visits across websites'),
  new CookieInfo('Advertising', '_gcl_au', 'Google Adsense', 'to store and track conversions'),
  new CookieInfo('Advertising', 'IDE', 'Google Ad Manager', 'facilitates remarketing on other websites with the DoubleClick ad network'),
  new CookieInfo('Essential', 'AWSALB', 'Amazon Application Load Balancer', 'to provide load balancing functionality'),
  new CookieInfo('Essential', '__cf_bm', 'CloudFlare', 'to read and filter requests from bots'),
  new CookieInfo('Essential', '__stripe_mid', 'Stripe', 'to provide fraud prevention'),
  new CookieInfo('Essential', '__stripe_sid', 'Stripe', 'to provide fraud prevention'),
  new CookieInfo('Essential', 'm', 'Stripe', 'to provide fraud prevention')
];
