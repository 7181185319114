import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router, ResolveEnd } from '@angular/router';

import { Countries } from '../../classes/countries';
import { environment } from 'environments/environment';
import { LocationsMenuDataService } from '../../services/locations-menu-data.service';
import { OrganizationService } from '../../services/organization.service';
import { TelemetryService } from '@shared/services/telemetry.service';

import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';

// Icons
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'cwk-locations-menu',
  templateUrl: './locations-menu.component.html',
  styleUrls: ['./locations-menu.component.scss', '../../shared/styles/header-menu.scss'],
  providers: [LocationsMenuDataService]
})
export class LocationsMenuComponent implements AfterViewChecked, OnInit {

  // Icons
  faSpinner = faSpinner;

  canSetTelemetry = false;
  countries = [];
  errorReading = '';
  isReading = true;
  showMobileUSA = false;
  sourcePath: string = null;
  urlRoot = environment.urlRoot;
  usaRegions = [];

  readonly URL_ROOT = environment.urlRoot;

  constructor(
    private locationsMenuDataService: LocationsMenuDataService,
    private organizationService: OrganizationService,
    private router: Router,
    private telemetryService: TelemetryService
  ) {

    // Set the source patch once the router has resolved the final route
    this.router.events.subscribe((routerData) => {
      if (routerData instanceof ResolveEnd) {
        this.sourcePath = routerData.url
        this.canSetTelemetry = !this.isReading;
        this.setTelemetry();
      }
    });
  }

  ngAfterViewChecked() {
    this.setTelemetry();
  }

  ngOnInit() {

    // Read the regions
    this.readRegions();
  }

  readRegions() {

    // Show spinner
    this.isReading = true;

    // Reset error status
    this.errorReading = '';

    // Read regions
    this.locationsMenuDataService.read()
      .subscribe(
        (value) => {
            this.countries = value;
            this.processRegions();
            this.isReading = false;
        },
        (error) => {
            this.errorReading = error;
            this.isReading = false;
        }
      );
  }

  private processRegions() {

    // Pull out the USA regions
    this.usaRegions = this.countries.splice(this.countries.findIndex(country => country.Country === 'US'), 1)[0].Regions;

    // Process the other countries from database
    this.countries.forEach(country => {
      country.URLIdentifier = country.Regions[0].URLIdentifier;
      country.Country = Countries.countries[country.Country].Name;
    });

    // Populate other countries that we don't store in the DB
    this.countries.push({
      Country: 'Australia',
      URLIdentifier: 'australia'
    });
    this.countries.push({
      Country: 'Canada',
      URLIdentifier: 'canada'
    });
    /*this.countries.push({
      Country: 'Nepal',
      URLIdentifier: 'nepal'
    });*/
    this.countries.push({
      Country: 'United Kingdom',
      URLIdentifier: 'united-kingdom'
    });

    // Sort countries by name
    this.countries.sort((a, b) => (a.Country > b.Country) ? 1 : -1);

    // Now that we have the regions & countries, set flag so that telemetry is set after the elements have been rendered
    this.canSetTelemetry = true;
  }

  private setTelemetry() {

    if (!this.canSetTelemetry) {

      // We haven't finished reading/processing the regions yet. Elements are not rendered into DOM yet!
      return;
    }

    // Update flag so we don't try to inject telemetry again
    this.canSetTelemetry = false;

    // Online programs
    this.telemetryService.inject('CLICK', 'locations-', this.sourcePath, true);
  }
}
