import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RegistrationCampMenuInfo } from '../interfaces/registration-camp-menu-info';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationCampDataService {

  private urlRegistrationCamp = environment.urlWebServices + '/cwk/registration/camp';

  constructor(private httpClient: HttpClient) { }

  readMenuInfo(partner: string): Observable<RegistrationCampMenuInfo[]> {

    // Call web service
    const url = `${this.urlRegistrationCamp}/menu-info/${partner}`;
    return this.httpClient.get(url)
      .pipe(
        map(this.fixMenuInfoFromMySQL),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  private fixMenuInfoFromMySQL(response: any): RegistrationCampMenuInfo[] {

    const info = structuredClone(response);

    info.forEach(season => {

      // Fix booleans
      season.IsOnline = !!season.IsOnline;

      // Fix dates
      season.StartOn = season.StartOn && new Date(season.StartOn.replace(/ /g, 'T') + 'Z');
    });

    return info;
  }
}
