import { Injectable } from '@angular/core';

import { AuthService } from '../modules/auth/services/auth.service';
import { LocalStorageService } from './local-storage.service';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  CWK_ORGANIZATION_ID = 1;

  private organizationId = new BehaviorSubject<number>(this.getOrganization());

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) { }

  // Get organizationId from local storage
  getOrganization() {
    return +this.localStorageService.getItem('organization');
  }

  getOrganizationChanged() {
    return this.organizationId.asObservable();
  }

  isCwkOrganization() {
    if (this.authService.isAuthenticated()) {

      // If the user is logged in, check if the organization from their home region is CwK
      const sessionInfo = this.authService.getSessionInfo();
      return sessionInfo.License ? sessionInfo.License.OrganizationId === this.CWK_ORGANIZATION_ID : false;
    } else {

      // Not logged in, see if the organization stored in local storage is CwK
      return this.getOrganization() === this.CWK_ORGANIZATION_ID;
    }
  }

  // Set organizationId into local storage
  setOrganization(organizationId) {
    this.localStorageService.setItem('organization', organizationId);

    // Let subscribers know we changed
    this.organizationId.next(organizationId);
  }
}

