import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ActivationStart, Router, NavigationEnd } from '@angular/router';

import { AuthService } from '@shared/modules/auth/services/auth.service';
import { MetaService } from '@shared/services/meta.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageHitSharingService } from '@shared/services/page-hit-sharing.service';
import { SessionQueryParamsService } from '@shared/services/session-query-params.service';
import { TrackingPixelService } from '@shared/services/tracking-pixel.service';
import { UtmParamsService } from '@shared/services/utm-params.service';

@Component({
  selector: 'home-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  activeTab: string = null;
  isCwKWeb = true;
  isLocaleEn = false;

  private trackingPrefix = '';

  // We need to inject SessionQueryParamsService to initialize / update the session storage
  constructor(
    private authService: AuthService,
    @Inject(LOCALE_ID) private locale: string,
    private metaService: MetaService,
    private modalService: NgbModal,
    private pageHitSharingService: PageHitSharingService, // Important to get the website hit stored
    public router: Router,
    private sessionQueryParamsService: SessionQueryParamsService,
    private trackingPixelService: TrackingPixelService,
    private utmParamsService: UtmParamsService // Important to watch UTM params in URL
  ) {

    // Check if we are on CwK web server
    try {
      console.log(window.top.location.href);
    } catch (error) {
      this.isCwKWeb = false;
    }

    this.router.events.subscribe(event => {
      if (event instanceof ActivationStart) {

        if (Object.keys(event.snapshot.data).length > 0) {

          // Set the active tab
          this.activeTab = event.snapshot.data.tab;

          // Update meta data
          this.metaService.updateDescription(event.snapshot.data.description);
          this.metaService.updateKeywords(event.snapshot.data.keywords);
          this.metaService.updateRobots(event.snapshot.data.robots);
          this.metaService.updateTitle(event.snapshot.data.title);
          this.metaService.updateUrl(window.location.href);
        }
      }
      if (event instanceof NavigationEnd) {

        // Generate website hit
        this.pageHitSharingService.recordPageHit();

        // Generate customer page view event
        this.trackingPixelService.pageView(this.trackingPrefix);

        // Close all open modals
        this.modalService.dismissAll();
      }
    });
  }

  ngOnInit() {

    // Check locale
    this.isLocaleEn = (this.locale === 'en');
  }

  hasRole(roles: string[]): boolean {
    return this.authService.isAuthorized(roles);
  }
}
