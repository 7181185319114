import { Component, Input, OnInit } from '@angular/core';

import { CustomerFeedbackRatingDataService } from './customer-feedback-rating-data.service';

// Icons
import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-customer-feedback-rating',
  templateUrl: './customer-feedback-rating.component.html',
  styleUrls: ['./customer-feedback-rating.component.scss'],
  providers: [CustomerFeedbackRatingDataService]
})
export class CustomerFeedbackRatingComponent implements OnInit {

  @Input() mode: string;

  average = null;
  errorReading = null;
  info = null;
  isCountShown: boolean = null;
  isReading = false;
  ratingStars = null;
  ratingWidths = [0, 0, 0, 0, 0];

  faInfoCircle = faInfoCircle;
  faSpinner = faSpinner;

  constructor(
    private customerFeedbackRatingDataService: CustomerFeedbackRatingDataService
  ) { }

  ngOnInit() {

    this.isCountShown = this.mode === 'testimonial';

    // Read average info
    this.readAverageInfo();
  }

  readAverageInfo() {

    // Show spinner
    this.isReading = true;

    // Reset error status
    this.errorReading = '';

    // Read the region data
    this.customerFeedbackRatingDataService.readInfo()
      .subscribe(
        (value) => {

          // Store the info
          this.info = value;

          // Round the average
          this.average = Math.round(this.info.AverageRating * 10) / 10;

          // Calculate the stars number
          this.ratingStars = Math.round(this.average * 2);

          // Calculate widths of stars
          // Average has to be converted to percentage ( / 5 * 100)
          const ratingWidth = (100 / this.ratingWidths.length);
          const average = this.average * 20;
          for (let i = 0; i < this.ratingWidths.length; i++) {
            this.ratingWidths[i] = ((average > (ratingWidth * (i + 1))) ?
              ratingWidth : Math.max(average - ratingWidth * i, 0)) / ratingWidth * 100;
          }

          // Clean error
          this.errorReading = null;

          // Hide spinner
          this.isReading = false;
          },
        (error) => {

          // Set error
          this.errorReading = 'ERROR: ' + error.data;
          console.error(this.errorReading);

          // Hide spinner
          this.isReading = false;
        }
      );
  }
}
