import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationDataService {

  private urlOrganization = environment.urlWebServices + '/api/organization';

  constructor(private http: HttpClient) { }

  read(organizationId: number): Observable<any> {

    // Call web service
    const url = `${this.urlOrganization}/${organizationId}`;
    return this.http.get(url)
      .pipe(catchError(error => observableThrowError(error.message || error)));
  }
}
