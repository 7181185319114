<ul *ngIf="geolocation" ngbNav>
  <li  [ngClass]="{'nav-item nav-link': mode !== 'MOBILE'}" ngbDropdown ngbNavItem>
    <div class="currency-container d-flex align-items-center">
      <span i18n class="cwk-grey dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="true" ngbDropdownToggle>
        <img class="img-fluid currency-flag-img" src="https://d3t4xfu733v2tb.cloudfront.net/web/flags/{{ geolocation.currency }}.jpg" alt="{{ geolocation.currency }}" loading="lazy"/>
      </span>
      <div class="dropdown-menu currency-menu" ngbDropdownMenu>
        <div class="select-currency">Select Currency:</div>
        <div *ngFor="let currency of currencies" class="dropdown-item menu-link-item menu-link-open" (click)="selectedCurrency(currency.Currency)">
          <img class="img-fluid currency-flag-img" width="24px" height="12px" src="https://d3t4xfu733v2tb.cloudfront.net/web/flags/{{ currency.Currency }}.jpg" alt="{{ currency.Name }}"/>
          <span class="currency-label" [ngClass]="{'bold': currency.Currency === geolocation.currency}">{{ currency.Currency }}</span>
        </div>
      </div>
    </div>
  </li>
</ul>

<!-- EMPTY ELEMENT WHILE WE DON'T HAVE GEOLOCATION -->
<div *ngIf="!geolocation" class="empty-container">&nbsp;</div>
