import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Subscription } from 'rxjs';

import { ConfigurationService } from '@shared/services/configuration.service';
import { DialogJoinMailingListComponent } from '@shared/components/dialog-join-mailing-list/dialog-join-mailing-list.component';
import { GeolocationService } from '@shared/services/geolocation.service';
import { HomepageDataService } from './services/homepage-data.service';
import { OrganizationService } from '@shared/services/organization.service';
import { TelemetryService } from '@shared/services/telemetry.service';
import { environment } from 'environments/environment';

// Animations
import { slideInOut } from '@shared/animations/slide-in-out';

// Icons
import { faChevronDown, faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  animations: [slideInOut],
  selector: 'home-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss', './components/customer-testimonials/customer-testimonials.component.scss']
})
export class HomepageComponent implements OnDestroy, OnInit {

  configuration = {
    bannerLeftBtn: null,
    bannerLeftBtnTitle: null,
    bannerRightBtn: null,
    bannerRightBtnTitle: null,
    programTile1: null,
    programTile2: null,
    programTile3: null,
    programTile4: null
  };
  errorReading = '';
  geolocation = null;
  isReading = false;
  isViewContentLoaded = false;
  totalOnlineStudents = null;
  urlRoot = environment.urlRoot;

  // Early-bird
  keyFileEarlyBird = 'EARLY-BIRD-FILE-ONLINE-CAMPS';
  keyShowEarlyBird = 'EARLY-BIRD-HIDE-TIMESTAMP-ONLINE-CAMPS';
  fileEarlyBird: string = null;
  showEarlyBird = false;

  // Icons
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  faSpinner = faSpinner;

  // Subscriptions
  private geolocationSubscription: Subscription;

  constructor(
    private configurationService: ConfigurationService,
    private geolocationService: GeolocationService,
    private homepageDataService: HomepageDataService,
    private location: Location,
    private modalService: NgbModal,
    private organizationService: OrganizationService,
    private route: ActivatedRoute,
    private router: Router,
    private telemetryService: TelemetryService
  ) { }

  ngOnDestroy() {

    // Unsubscribe
    if (this.geolocationSubscription) {
      this.geolocationSubscription.unsubscribe();
    }
  }

  ngOnInit() {

    // Read data to initialize the page
    this.readInit();

    // Set the organization to CwK
    this.organizationService.setOrganization(this.organizationService.CWK_ORGANIZATION_ID);

    // If there is a fragment, scroll to it
    if (this.route.snapshot.fragment) {
      this.scrollToAnchor(this.route.snapshot.fragment, 250);
    } else {
      window.scrollTo(0, 0);
    }

    // Get current geolocation info
    this.geolocationSubscription = this.geolocationService.getObservable().subscribe((value) => this.geolocation = value);

    // Get early-bird configuration
    this.configurationService.getItem(this.keyFileEarlyBird)
      .subscribe((value) => this.fileEarlyBird = value.Value);

    this.configurationService.getItem(this.keyShowEarlyBird)
      .subscribe((value) => this.showEarlyBird = value && (new Date(value.Value) > new Date()));
  }

  openJoinMailingListDialog() {

    // Open the dialog
    const modalRef = this.modalService.open(DialogJoinMailingListComponent, { size: 'lg', centered: true });
    modalRef.componentInstance.countryCode = this.geolocation && this.geolocation.country_code;
    modalRef.componentInstance.source = 'Homepage';
  }

  scrollToAnchor(location: string, wait = 0): void {
    const element = document.querySelector('#' + location);
    if (element) {
      setTimeout(() => {

        // Scroll to the fragment
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

        // Remove the fragment
        this.location.replaceState('');
      }, wait);
    }
  }

  private readInit() {

    // Show spinner
    this.isReading = true;

    // Read
    this.homepageDataService.readInit()
      .subscribe(
        (value) => {

          // Copy over configuration values
          this.configuration.bannerLeftBtn = value.Configuration.find(c => c.KeyName === 'HOMEPAGE-BANNER-BTN-1').Value;
          this.configuration.bannerLeftBtnTitle = value.Configuration.find(c => c.KeyName === 'HOMEPAGE-BANNER-BTN-1-TITLE').Value;
          this.configuration.bannerRightBtn = value.Configuration.find(c => c.KeyName === 'HOMEPAGE-BANNER-BTN-2').Value;
          this.configuration.bannerRightBtnTitle = value.Configuration.find(c => c.KeyName === 'HOMEPAGE-BANNER-BTN-2-TITLE').Value;
          this.configuration.programTile1 = value.Configuration.find(c => c.KeyName === 'HOMEPAGE-PROGRAM-TILE-1').Value;
          this.configuration.programTile2 = value.Configuration.find(c => c.KeyName === 'HOMEPAGE-PROGRAM-TILE-2').Value;
          this.configuration.programTile3 = value.Configuration.find(c => c.KeyName === 'HOMEPAGE-PROGRAM-TILE-3').Value;
          this.configuration.programTile4 = value.Configuration.find(c => c.KeyName === 'HOMEPAGE-PROGRAM-TILE-4').Value;

          // Set telemetry after the next digest (so elements are rendered in DOM)
          setTimeout(() => this.setTelemetry());
          this.isReading = false;
        },
        (error) => {
          this.errorReading = error;
          this.isReading = false;
        }
      );
  }

  private setTelemetry() {
    this.telemetryService.inject('CLICK', 'coders-ladder-learn-more-button', '/', true);
    this.telemetryService.inject('CLICK', 'coders-pathways-learn-more-button', '/', true);
    this.telemetryService.inject('CLICK', 'program-banner-camp', '/', true);
    this.telemetryService.inject('CLICK', 'program-banner-coders-ladder', '/', true);
    this.telemetryService.inject('CLICK', 'program-banner-enrichment', '/', true);
    this.telemetryService.inject('CLICK', 'program-tile-camp', '/', true);
    this.telemetryService.inject('CLICK', 'program-tile-coders-ladder', '/', true);
    this.telemetryService.inject('CLICK', 'program-tile-enrichment', '/', true);
    this.telemetryService.inject('CLICK', 'program-tile-private', '/', true);
    /*this.telemetryService.inject('CLICK', 'seasonal-update-button', '/', true);
    this.telemetryService.inject('CLICK', 'seasonal-update-cl-classes', '/', true);
    this.telemetryService.inject('CLICK', 'seasonal-update-enrichment-classes', '/', true);
    this.telemetryService.inject('CLICK', 'seasonal-update-private-classes', '/', true);
    this.telemetryService.inject('CLICK', 'seasonal-update-camps', '/', true);
    this.telemetryService.inject('CLICK', 'seasonal-update-parent-classes', '/', true);
    this.telemetryService.inject('CLICK', 'seasonal-update-enrichment-classes-coord', '/', true);
    this.telemetryService.inject('CLICK', 'seasonal-update-phinney-camps', '/', true);*/
    this.telemetryService.inject('CLICK', 'read-more-for-schools', '/', true);
    this.telemetryService.inject('CLICK', 'read-more-as-enrichment', '/', true);
    this.telemetryService.inject('CLICK', 'testimonials-scroll-left', '/', true);
    this.telemetryService.inject('CLICK', 'testimonials-scroll-right', '/', true);
    this.telemetryService.inject('CLICK', 'today-video', '/', true);
  }
}
