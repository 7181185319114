<div class="d-flex flex-column justify-content-between h-100">
  <div>

    <!-- Review -->
    <div #reviewBody class="testimonial limit-lines" [ngStyle]="{'-webkit-line-clamp' : showMoreReview ? 1000 : limitLines}">
      <sup><fa-icon [icon]="faQuoteLeft"></fa-icon></sup>&nbsp;&nbsp;&nbsp;<span [innerHTML]="review"></span>&nbsp;&nbsp;&nbsp;<sup><fa-icon [icon]="faQuoteRight"></fa-icon></sup>
    </div>

    <!-- Show more button -->
    <div *ngIf="isReviewClamped">
      <span class="cwk-link cwk-blue-link" (click)="switchShowMoreReview()">{{ showMoreReview ? 'Show less...' : 'Read more...' }}</span>
    </div>
  </div>

  <!-- 5-stars and signature -->
  <div class="mt-2 d-flex justify-content-between align-items-center">
    <img class="img-fluid stars" [src]="'https://d3t4xfu733v2tb.cloudfront.net/web/rating-stars/rating-stars-' + stars + '.png'" alt="Customer feedback rating stars" loading="lazy" width="368" height="64" />
    <div>
      <div class="signature">- {{ author }}</div>
      <div *ngIf="date" class="signature-date">{{ date | date:'shortDate' }}</div>
    </div>
  </div>
</div>
