import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { CookieConsentCompliance } from '../enums/cookie-consent-compliance';
import { CookieConsentData } from '../interfaces/cookie-consent-data';
import { CookieConsentLaw } from '../classes/cookie-consent-law';
import { GeolocationData, GeolocationService } from '../../../services/geolocation.service';
import { LocalStorageService } from '../../../services/local-storage.service';

const COOKIE_CONSENT_LOCAL_STORAGE_KEY = 'COOKIE-CONSENTS';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentManagerService implements OnDestroy {

  // Model
  private compliance: CookieConsentCompliance = null;
  private consents: CookieConsentData = null;

  // Subjects
  private initializationSubject = new BehaviorSubject<CookieConsentCompliance>(null);
  private manageCookiesSubject = new Subject<void>;
  private updateCookiesSubject = new Subject<void>;

  // Subscriptions
  private geolocationSubscription: Subscription;

  constructor(
    private geolocationService: GeolocationService,
    private localStorageService: LocalStorageService
  ) {
    this.initialize();
  }

  ngOnDestroy() {

    // Clean up the subscriptions
    if (this.geolocationSubscription) {
      this.geolocationSubscription.unsubscribe();
    }
  }

  getInitializationObservable(): Observable<CookieConsentCompliance> {
    return this.initializationSubject.asObservable();
  }

  getManageCookiesObservable(): Observable<void> {
    return this.manageCookiesSubject.asObservable();
  }

  getUpdateCookiesObservable(): Observable<void> {
    return this.updateCookiesSubject.asObservable();
  }

  hasConsented(key: string): boolean {
    return !!this.consents[key];
  }

  manageCookies(): void {
    this.manageCookiesSubject.next();
  }

  update(consents: CookieConsentData): void {
    this.consents = consents;
    this.updateLocalStorage();

    // Emit the cookie change event
    // console.log('EMIT CHANGE', consents);
    this.updateCookiesSubject.next();
  }

  private initialize(): void {

    // Check whether the cookie consent has already been provided
    this.readLocalStorage();

    // If the cookie consent has not been provided yet, check the geolocation whether the cookie consents are required
    this.geolocationSubscription = this.geolocationService.getObservable()
      .subscribe(
        (value: GeolocationData) => this.onGeolocation(value)
      );
  }

  private onGeolocation(data: GeolocationData): void {

    // console.log('GEOLOACTION: ', this.consents);
    // If consents are already given (the essential functionality coosent is given), do nothing
    if (this.consents && this.consents.FunctionalityStorage) {
      // console.log('CONSENTS ALREADY GIVEN', this.consents);
      this.initializationSubject.next(CookieConsentCompliance.RESTORED);
      return;
    }

    const compliance: CookieConsentCompliance = CookieConsentLaw.getCompliance(data.country_code, data.region_code);
    // console.log('GOT COMPLIANCe: ' + compliance);

    // Emit new compliance
    this.initializationSubject.next(compliance);
  }

  private readLocalStorage(): void {

    const consents = this.localStorageService.getItem(COOKIE_CONSENT_LOCAL_STORAGE_KEY);
    const consentsData = JSON.parse(consents) as CookieConsentData;

    // Set the consents
    // Even though the functionality storage is mandatory, we keep it null if not provided to detect that we don't have the consent yet
    this.consents = {
      AdPersonalization: consentsData && consentsData.AdPersonalization,
      AdStorage: consentsData && consentsData.AdStorage,
      AdUserData: consentsData && consentsData.AdUserData,
      AnalyticsStorage: consentsData && consentsData.AnalyticsStorage,
      FunctionalityStorage: consentsData && consentsData.FunctionalityStorage,
      PersonalizationStorage: consentsData && consentsData.PersonalizationStorage,
      SecurityStorage: consentsData && consentsData.SecurityStorage
    };
    // console.log('READ LOCAL STORAGE', this.consents);
  }

  private updateLocalStorage(): void {
    // console.log('UPDATE LOCAL STORAGE', this.consents);
    this.localStorageService.setItem(COOKIE_CONSENT_LOCAL_STORAGE_KEY, JSON.stringify(this.consents));
  }
}
