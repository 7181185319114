export class Currencies {

  static currencies = [
    { Countries: ['CA'], Currency: 'CAD', Name: 'Canadian dollar' },
    { Countries: ['GB'], Currency: 'GBP', Name: 'Pound sterling' },
    { Countries: ['US'], Currency: 'USD', Name: 'United States dollar' }
  ];

  static defaultCurrency(): any {
    return Currencies.currencies.find(currency => currency.Currency === 'USD');
  }

  static getCurrency4CountryCode(countryCode: string): any {
    const currency4Country = Currencies.currencies.find(currency => currency.Countries.includes(countryCode));
    return (currency4Country ? currency4Country : Currencies.defaultCurrency()).Currency;
  }
}
