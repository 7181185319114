import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BlogDataService {

  constructor(
    private http: HttpClient
  ) { }

  private urlBlog = environment.urlWebServices + '/api/blog';

  read(labels): Observable<any> {

    const params = new HttpParams()
      .set('labels', labels);

    return this.http.get<any>(`${this.urlBlog}`, { params })
      .pipe(
        map(response => this.fixFromMySQL(response)),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  readPost(path: string): Observable<any> {
    return this.http.get<any>(`${this.urlBlog}/${path}`)
      .pipe(
        map(response => this.fixSinglePostFromMySQL(response)),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  private fixFromMySQL(data) {

    // Parse the response
    data = data ? JSON.parse(data) : {};

    // Check that the blog has some items (posts)
    if (data.items) {

      // Fix the timestamps
      data.items.forEach(post => {
        post.published = new Date(post.published);
        post.updated = new Date(post.updated);
      });
    }

    return data;
  }

  private fixSinglePostFromMySQL(post) {

    // Parse the response
    post = post ? JSON.parse(post) : {};

    // Fix the timestamps
    post.published = new Date(post.published);
    post.updated = new Date(post.updated);

    return post;
  }
}
