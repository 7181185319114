<div class="modal-body">
  <h4 class="cwk-orange">Your Cookie Preferences</h4>
  <p>We use different types of cookies to optimize your experience on our website. You may choose which types of cookies to allow and can change your preferences at any time. Remember that disabling cookies may affect your experience on the website.</p>
  <form>

    <hr/>

    <!-- ESSENTIAL COOKIES -->
    <h6>Essential Cookies</h6>
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" id="essential" name="essential" [(ngModel)]="consents.FunctionalityStorage" disabled />
      <label class="form-check-label" for="essential" style="opacity: 1">
        These cookies are necessary to the core functionality of our website and some of its features, such as access to secure areas or payments.
        <span class="cwk-link cwk-blue-link float-end" (click)="$event.preventDefault(); switchCookiesEssentialInfo()">{{ isCookiesEssentialShown ? 'Hide' : 'Show' }} {{ cookiesEssential.length }} cookie{{ cookiesEssential.length === 1 ? '' : 's' }}</span>
      </label>
    </div>
    <ng-container [ngTemplateOutlet]="cookiesInfo" [ngTemplateOutletContext]="{ $implicit: cookiesEssential }" *ngIf="isCookiesEssentialShown"></ng-container>
    <hr/>

    <!-- ANALYTICS COOKIES -->
    <h6>Analytics Cookies</h6>
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" id="analytics" name="analytics" [(ngModel)]="consents.AnalyticsStorage" />
      <label class="form-check-label" for="analytics" style="opacity: 1">
        Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.
        <span class="cwk-link cwk-blue-link float-end" (click)="$event.preventDefault(); switchCookiesAnalyticsInfo()">{{ isCookiesAnalyticsShown ? 'Hide' : 'Show' }} {{ cookiesAnalytics.length }} cookie{{ cookiesAnalytics.length === 1 ? '' : 's' }}</span>
      </label>
    </div>
    <ng-container [ngTemplateOutlet]="cookiesInfo" [ngTemplateOutletContext]="{ $implicit: cookiesAnalytics }" *ngIf="isCookiesAnalyticsShown"></ng-container>
    <hr/>

    <!-- ADVERTISING COOKIES -->
    <h6>Advertising Cookies</h6>
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" id="advertising" name="advertising" [(ngModel)]="consents.AdStorage" />
      <label class="form-check-label" for="advertising" style="opacity: 1">
        Advertising cookies are used to analyze the effectiveness of our ad campaigns.
        <span class="cwk-link cwk-blue-link float-end" (click)="$event.preventDefault(); switchCookiesAdvertisingInfo()">{{ isCookiesAdvertisingShown ? 'Hide' : 'Show' }} {{ cookiesAdvertising.length }} cookie{{ cookiesAdvertising.length === 1 ? '' : 's' }}</span>
      </label>
    </div>
    <ng-container [ngTemplateOutlet]="cookiesInfo" [ngTemplateOutletContext]="{ $implicit: cookiesAdvertising }" *ngIf="isCookiesAdvertisingShown"></ng-container>
    <hr/>
  </form>

  <!-- BUTTONS -->
  <div class="d-flex flex-wrap">
    <button type="button" class="btn btn-light mb-1 me-auto" (click)="saveRejectAll()" i18n>Reject Non-essential</button>
    <div class="d-flex flex-grow-1 flex-wrap justify-content-end">
      <button type="button" class="btn btn-light mb-1 ms-3" (click)="saveChoices()" i18n>Save My Preferences</button>
      <button type="button" class="btn btn-warning ms-3" (click)="saveAgreeAll()" i18n>Accept All</button>
    </div>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template #cookiesInfo let-cookies>
  <div class="p-2" [@slideInOut]>
    <table class="table table-hover table-sm table-striped">
      <thead>
        <tr>
          <th>Name</th>
          <th>Used By</th>
          <th>Purpose</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cookie of cookies; last as isLast">
          <td>{{ cookie.Name }}</td>
          <td>{{ cookie.UsedBy }}</td>
          <td>{{ cookie.Description }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
