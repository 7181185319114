
import { throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable()
export class RegionSwitchHttpService {

  private urlReadRegions = environment.urlWebServices + '/api/locations/regions/assigned';
  private urlUpdateSelected = environment.urlWebServices + '/api/locations/regions/assigned/selected';

  constructor(private http: HttpClient) { }

  readRegions(): Observable<any[]> {

    // Call web service
    return this.http.get<any[]>(this.urlReadRegions)
      .pipe(
        map(this.fixFromMySQL),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  updateSelected(regions): Observable<any> {

    // Fix before sending to MySQL
    const payload = this.fixToMySQL(regions);

    // Call web service
    return this.http.post(this.urlUpdateSelected, payload);
  }

  private fixFromMySQL(regions) {
    regions.forEach(region => {
      region.IsActive = !!region.IsActive;
      region.IsSelected = !!region.IsSelected;
    });
    return regions;
  }

  private fixToMySQL(regions) {
    regions.forEach(region => region.IsSelected = (region.IsSelected ? 1 : 0));
    return regions;
  }
}

