import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Currencies } from '../../classes/currencies';
import { GeolocationService } from '../../services/geolocation.service';

@Component({
  selector: 'cwk-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss', '../../shared/styles/header-menu.scss']
})
export class CurrencySelectorComponent implements OnInit {

  @Input() mode: string;

  currencies = Currencies.currencies;
  geolocation = null;

  constructor(
    private geolocationService: GeolocationService
  ) { }

  ngOnInit() {

    // Get current geolocation info
    this.geolocationService.getObservable()
      .subscribe((value) => this.geolocation = value);
  }

  selectedCurrency(currency: any) {
    this.geolocationService.setCurrency(currency);
  }
}
