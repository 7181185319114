<div class="row mt-4">

  <!-- TODAY show video -->
  <div class="col-lg-6 mb-4 mb-lg-0">
    <div class="row mb-3 align-items-center">
      <div class="col-lg-5 justify-content-center">
        <div class="text-center">
          <img alt="NBC's TODAY logo" style="max-width: 180px;" class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/today.png" loading="lazy"/>
        </div>
      </div>
      <div class="col-lg-7 text-center">
        <div class="label">As featured on NBC's</div>
        <div class="label"><b>The TODAY Show</b></div>
      </div>
    </div>
    <div id="today-video" class="video-container">
      <a class="cwk-pointer" target="_blank" href="https://www.today.com/video/coding-with-kids-inspires-next-generation-of-computer-whizzes-63933509736">
        <img alt="NBC's TODAY video" class="highlight-hover img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/today-video-thumbnail-1.jpg" loading="lazy" width="1158" height="652" />
      </a>
    </div>
  </div>

  <!-- Parenting OC & NW Kids Magazine -->
  <div class="col-lg-6">
    <div class="d-flex flex-column justify-content-around h-100">
      <div class="d-flex justify-content-center mb-4">
        <div class="d-flex justify-content-center me-4">
          <img alt="Parenting OC Magazine Voted best coding class" class="orange-country-logo" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/awards/OC-2023-badge.png" loading="lazy"/>
        </div>
        <div class="d-flex flex-column justify-content-center col-container-mobile">
          <div class="label"><b>Parenting OC Magazine</b></div>
          <div class="label">Voted Best Coding Class</div>
        </div>
      </div>

      <div class="d-flex justify-content-center my-3">
        <div class="d-flex justify-content-center me-4">
          <img alt="Parenting OC Magazine Voted best Science/tech Camp" class="orange-country-logo" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/awards/OC-badge.png" loading="lazy"/>
        </div>
        <div class="d-flex flex-column justify-content-center col-container-mobile">
          <div class="label"><b>Parenting OC Magazine</b></div>
          <div class="label">Voted Best Tech Camp</div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-4">
        <div class="d-flex justify-content-center me-4">
          <img class="nw-kids-logo" alt="NW Kids Voted best STEM Camp" src="https://d3t4xfu733v2tb.cloudfront.net/logo/nwkids_stem.png" loading="lazy"/>
        </div>
        <div class="d-flex flex-column justify-content-center col-container-mobile">
          <div class="label"><b>NW Kids Magazine</b></div>
          <div class="label">Voted Best STEM Camp</div>
        </div>
      </div>
    </div>
  </div>
</div>
