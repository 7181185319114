import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable()
export class FreshchatDataService {

  private urlStaff = environment.urlWebServices + '/api/staff';

  constructor(private http: HttpClient) { }

  readFreshchatStatus(): Observable<any> {

    // Call web service
    return this.http.get(`${this.urlStaff}/freshchat`)
      .pipe(catchError(error => observableThrowError(error.message || error)));
  }

  readFreshchatStatusAll(): Observable<any> {

    // Call web service
    return this.http.get(`${this.urlStaff}/freshchatAll`)
      .pipe(catchError(error => observableThrowError(error.message || error)));
  }

  updateFreshchatStatus(status): Observable<any> {

    // Call web service
    return this.http.post(`${this.urlStaff}/freshchat/${status}`, null)
      .pipe(catchError(error => observableThrowError(error.message || error)));
  }
}
