import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpecialPageSharingService {

  constructor() { }

  staticPrice4ClassType4Currency(classType: string, currency: string, discountCode: string): [number, number] {
    switch (classType) {
      case 'ADULT CLASS':
        switch (currency) {
          case 'CAD':
            return [280, null];
          case 'GBP':
            return [160, null];
          default:
            return [199, null];
        }
        break;
      case 'AFTER-SCHOOL 6WK':
        switch (currency) {
          case 'CAD':
            return [190, null];
          case 'GBP':
            return [112, null];
          default:
            return [139, null];
        }
        break;
      case 'AFTER-SCHOOL 10WK':
        switch (currency) {
          case 'CAD':
            return [326, null];
          case 'GBP':
            return [192, null];
          default:
            return [239, null];
        }
        break;
      /*case 'CAMP 1HR':
        switch (currency) {
          case 'CAD':
            return [159, null];
          case 'GBP':
            return [99, null];
          default:
            return [119, null];
        }
        break;
      case 'CAMP 2HR':
        switch (currency) {
          case 'CAD':
            return [339, null];
          case 'GBP':
            return [199, null];
          default:
            return [249, null];
        }
        break;*/
      case 'CAMP 2.5HR':
        switch (currency) {
          case 'CAD':
            return [328, null];
          case 'GBP':
            return [197, null];
          default:
            return [239, null];
        }
        break;
      case 'CAMP FULL':
        switch (currency) {
          case 'CAD':
            return [720, null];
          case 'GBP':
            return [433, null];
          default:
            return [525, null];
        }
        break;
      case 'CAMP HALF':
        switch (currency) {
          case 'CAD':
            return [397, null];
          case 'GBP':
            return [238, null];
          default:
            return [289, null];
        }
        break;
      case 'CAMP HALF IN-PERSON':
        switch (currency) {
          case 'CAD':
            return [438, null];
          case 'GBP':
            return [263, null];
          default:
            return [319, null];
        }
        break;
      /*case 'CAMP 1HR WINTER':
        switch (currency) {
          case 'CAD':
            return [135, null];
          case 'GBP':
            return [79, null];
          default:
            return [96, null];
        }
        break;
      case 'CAMP 2HR WINTER':
        switch (currency) {
          case 'CAD':
            return [249, null];
          case 'GBP':
            return [139, null];
          default:
            return [175, null];
        }
        break;*/
      case 'CENTER CLASS':
        switch (currency) {
          case 'CAD':
            return [171, null];
          case 'GBP':
            return [100, null];
          default:
            return [125, null];
        }
        break;
      case 'CENTER CLASS 2X':
        switch (currency) {
          case 'CAD':
            return [319, null];
          case 'GBP':
            return [186, null];
          default:
            return [232.5, null];
        }
        break;
      case 'PRIVATE CLASS':
        switch (currency) {
          case 'CAD':
            return [329, null];
          case 'GBP':
            return [192, null];
          default:
            return [240, null];
        }
        break;
      case 'PRIVATE CLASS WEEK':
        switch (currency) {
          case 'CAD':
            return [70, null];
          case 'GBP':
            return [40, null];
          default:
            return [50, null];
        }
        break;
      default:
        return [null, null];
    }
  }
}
