import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { LocalStorageService } from './local-storage.service';

interface UtmParams {
  p: string | null;
  utm_campaign: string | null;
  utm_content: string | null;
  utm_medium: string | null;
  utm_source: string | null;
  utm_term: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class UtmParamsService {

  // Attribution period is 90 days (stored in seconds)
  attributionPeriod = 90 * 24 * 60 * 60;

  private utmParams: UtmParams = {
    p: null, // Internal partner url id
    utm_campaign: null,
    utm_content: null,
    utm_medium: null,
    utm_source: null,
    utm_term: null
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) {

    // Watch URL params
    this.activatedRoute.queryParamMap.subscribe(params => this.updateParams(params));
  }

  // Return a value of a single utm param
  getParam(key: keyof UtmParams): string | null {
    return this.utmParams[key];
  }

  // Return query string with all set utm params
  getQueryString(): string {

    const queryStringParams = [];

    for (const key in this.utmParams) {
      if (this.utmParams[key as keyof UtmParams]) {
        queryStringParams.push(key + '=' + this.utmParams[key as keyof UtmParams]);
      }
    }

    return queryStringParams.join('&');
  }

  // Params have changed, update them internally
  private updateParams(params: ParamMap): void {

    const now = new Date();

    // Are UTM parameters passed through URL?
    let isUtmUpdated = false;

    for (const key in this.utmParams) {
      if (this.utmParams.hasOwnProperty(key)) {
        this.utmParams[key as keyof UtmParams] = params.get(key);

        // Check if this is UTM key
        if (key.startsWith('utm_') && (this.utmParams[key as keyof UtmParams])) {
          isUtmUpdated = true;
        }
      }
    }

    // If UTM params have been updated, store them in local storage with timestamp
    if (isUtmUpdated) {

      this.localStorageService.setItem('UTM_EXPIRATION', Math.floor(now.getTime() / 1000));
      this.localStorageService.setItem('UTM_CAMPAIGN', this.utmParams.utm_campaign);
      this.localStorageService.setItem('UTM_CONTENT', this.utmParams.utm_content);
      this.localStorageService.setItem('UTM_MEDIUM', this.utmParams.utm_medium);
      this.localStorageService.setItem('UTM_SOURCE', this.utmParams.utm_source);
      this.localStorageService.setItem('UTM_TERM', this.utmParams.utm_term);
    } else {

      // UTM params are not in URL, check the expiration
      let expiry = this.localStorageService.getItem('UTM_EXPIRATION');

      if (expiry) {

        // UTM params are stored in the local storage, check their validity
        if (((parseInt(expiry, 10) + this.attributionPeriod) * 1000) > now.getTime()) {

          // UTM params are valid, retrieve them from the local storage
          this.utmParams.utm_campaign = this.localStorageService.getItem('UTM_CAMPAIGN');
          this.utmParams.utm_content = this.localStorageService.getItem('UTM_CONTENT');
          this.utmParams.utm_medium = this.localStorageService.getItem('UTM_MEDIUM');
          this.utmParams.utm_source = this.localStorageService.getItem('UTM_SOURCE');
          this.utmParams.utm_term = this.localStorageService.getItem('UTM_TERM');
        } else {

          // UTM params expired, remove them
          this.localStorageService.removeItem('UTM_EXPIRATION');
          this.localStorageService.removeItem('UTM_CAMPAIGN');
          this.localStorageService.removeItem('UTM_CONTENT');
          this.localStorageService.removeItem('UTM_MEDIUM');
          this.localStorageService.removeItem('UTM_SOURCE');
          this.localStorageService.removeItem('UTM_TERM');
        }
      }
    }
  }
}
