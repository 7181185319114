import { Injectable } from '@angular/core';

import { ConfigurationDataService } from './configuration-data.service'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(
    private configurationDataService: ConfigurationDataService
  ) { }

  // Call the web service to get configuration for the key
  getItem(key): Observable<any> {
    return this.configurationDataService.read(key);
  }
}
