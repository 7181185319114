<div class="alert alert-danger" role="alert">
  <h3 class="mb-3"><fa-icon class="me-2" [icon]="faExclamationTriangle"></fa-icon>Oops, an error has occurred.</h3>
  <div class="mb-3">
    Please check whether your internet connection is working properly and <span class="cwk-link cwk-blue-link" (click)="refresh()">refresh the page</span>. If you continue to have issues please <a target="_blank" href="/contact?s=Website%20Error" class="cwk-blue-link cwk-link">contact us</a> with the error message below.
  </div>
  <ng-container *ngIf="!isHtml">
    <strong>Error message:</strong> {{ error | json }}
  </ng-container>
  <ng-container *ngIf="isHtml">
    <strong>Error message:</strong>
    <div [innerHtml]="error"></div>
  </ng-container>
</div>
