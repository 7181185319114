import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerTestimonialsDataService {

  constructor(
    private http: HttpClient
  ) { }

  private urlCustomerFeedback = environment.urlWebServices + '/cwk/customerFeedback';

  readPublic(): Observable<any> {

    // Call web service
    const getURL = `${this.urlCustomerFeedback}/public`;
    return this.http.get<any>(getURL)
      .pipe(
        tap(this.fixFromMySQL),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  private fixFromMySQL(data) {

    data.forEach(feedback => {

      // Fix date
      feedback.CreatedOn = feedback.CreatedOn && new Date(feedback.CreatedOn.replace(/ /g, 'T') + 'Z');
    });
  }
}
